export enum EstadosEnum {
  ACRE = 'ACRE',
  ALAGOAS = 'ALAGOAS',
  AMAPA = 'AMAPÁ',
  AMAZONAS = 'AMAZONAS',
  BAHIA = 'BAHIA',
  CEARA = 'CEARÁ',
  DISTRITO_FEDERAL = 'DISTRITO FEDERAL',
  ESPIRITO_SANTO = 'ESPÍRITO SANTO',
  GOIAS = 'GOIÁS',
  MARANHAO = 'MARANHÃO',
  MATO_GROSSO = 'MATO GROSSO',
  MATO_GROSSO_DO_SUL = 'MATO GROSSO DO SUL',
  MINAS_GERAIS = 'MINAS GERAIS',
  PARA = 'PARÁ',
  PARAIBA  = 'PARAÍBA',
  PARANA = 'PARANÁ',
  PERNAMBUCO = 'PERNAMBUCO',
  PIAUI= 'PIAUÍ',
  RIO_DE_JANEIRO = 'RIO DE JANEIRO',
  RIO_GRANDE_DO_NORTE = 'RIO GRANDE DO NORTE',
  RIO_GRANDE_DO_SUL  = 'RIO GRANDE DO SUL',
  RONDONIA = 'RONDÔNIA',
  RORAIMA = 'RORAIMA',
  SANTA_CATARINA  = 'SANTA CATARINA',
  SAO_PAULO  = 'SÃO PAULO',
  SERGIPE = 'SERGIPE',
  TOCANTINS = 'TOCANTINS',
}

export const EstadosOptions = new Map([
[EstadosEnum.ACRE, 'AC'],
[EstadosEnum.ALAGOAS, 'AL'],
[EstadosEnum.AMAPA, 'AP'],
[EstadosEnum.AMAZONAS, 'AM'],
[EstadosEnum.BAHIA, 'BA'],
[EstadosEnum.CEARA, 'CE'],
[EstadosEnum.DISTRITO_FEDERAL, 'DF'],
[EstadosEnum.ESPIRITO_SANTO, 'ES'],
[EstadosEnum.GOIAS, 'GO'],
[EstadosEnum.MARANHAO, 'MA'],
[EstadosEnum.MATO_GROSSO, 'MT'],
[EstadosEnum.MATO_GROSSO_DO_SUL, 'MS'],
[EstadosEnum.MINAS_GERAIS, 'MG'],
[EstadosEnum.PARA, 'PA'],
[EstadosEnum.PARAIBA, 'PB'],
[EstadosEnum.PARANA, 'PR'],
[EstadosEnum.PERNAMBUCO, 'PE'],
[EstadosEnum.PIAUI, 'PI'],
[EstadosEnum.RIO_DE_JANEIRO, 'RJ'],
[EstadosEnum.RIO_GRANDE_DO_NORTE, 'RN'],
[EstadosEnum.RIO_GRANDE_DO_SUL, 'RS'],
[EstadosEnum.RONDONIA, 'RO'],
[EstadosEnum.RORAIMA, 'RR'],
[EstadosEnum.SANTA_CATARINA, 'SC'],
[EstadosEnum.SAO_PAULO, 'SP'],
[EstadosEnum.SERGIPE, 'SE'],
[EstadosEnum.TOCANTINS, 'TO'],
]);
