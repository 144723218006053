import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-titulo-aplicacao',
  templateUrl: './titulo-aplicacao.component.html',
  styleUrls: ['./titulo-aplicacao.component.scss']
})
export class TituloAplicacaoComponent implements OnInit {

  @Input() texto: string | undefined;

  constructor() { }

  ngOnInit() {
  }

}
