<!-- <nav class="navbar navbar-default">
  <div class="container-fluid">

    <div class="navbar-header">
      <a class="navbar-brand" routerLink="">MPAtende</a>
    </div>

    <ul class="nav navbar-nav">
        <li routerLinkActive="active">
          <a routerLink="/atendimento">Atendimento</a>
        </li>
    </ul>
  </div>
</nav> -->

<div class="container">
  <router-outlet></router-outlet>
</div>

<!-- <app-header></app-header>
<app-nav></app-nav>
<app-footer></app-footer> -->
