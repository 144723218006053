import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cidade } from '../../modelos/cidade.model';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class SelecionarEstadoCidadeService {

  private URLsemestado = `${environment.back_atendimento}/listarCidades?uf=`;

  constructor(private http: HttpClient) {}

  // Lista cidades
  getCidadesMoradia(estado : string): Observable<Cidade[]> {
    return this.http.get<Cidade[]>(this.URLsemestado+estado);
  }
}
