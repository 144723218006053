import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-denuncia-eleitoral',
  templateUrl: './denuncia-eleitoral.component.html',
  styleUrls: ['./denuncia-eleitoral.component.scss']
})
export class DenunciaEleitoralComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
