import { Atendimento } from '../../modelos/atendimento.model';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { DadosAtendimentoService } from './dados-atendimento.service';
import { DataHoraService } from '../../../shared/services/datahora.service';
import { MaskPipe } from 'ngx-mask';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dados-atendimento',
  templateUrl: './dados-atendimento.component.html',
  styleUrls: ['./dados-atendimento.component.scss'],
})

export class DadosAtendimentoComponent implements OnInit, OnChanges {

  @Input() atendimento!: Atendimento;
  @Input() texto: string | undefined;
  @Input() tipo: string | undefined;

  constructor(
    private router: Router,
    private dadosAtendimentoService : DadosAtendimentoService,
    private readonly dataHoraService: DataHoraService,
    private maskPipe: MaskPipe
  ) {
      this.router = router;
    }

  ngOnInit(): void {
  }

  // Array de dados a serem apresentados
  d: string[] = [];

  cardConsulta: string = 'none';

  ngOnChanges(): void {

  if (this.atendimento.atendimento.numProtocoloEnviado != null){
    this.tipo = 'enviado';
  }
    /* 00 */ var CEP: string = (this.atendimento.enderecoUnidade?.cep == null)?'':this.atendimento.enderecoUnidade?.cep.toString();
    /* 01 */ var cidadeFato: string = this.atendimento.atendimento.cidadeFato;
    /* 02 */ var complemento: string = (this.atendimento.enderecoUnidade?.complemento == null)?'':this.atendimento.enderecoUnidade?.complemento.toString();
    /* 03 */ var dataCadastro: string = moment(this.atendimento.atendimento.dataHoraCadastro).format('DD/MM/YYYY HH:mm:ss');
    /* 04 */ var dataConsulta: string = moment(this.dataHoraService.obtemDataHora()).format('DD/MM/YYYY HH:mm:ss');
    /* 05 */ var dataNascimento: string = (this.atendimento.pessoa.dataNascimento  == '' || this.atendimento.pessoa.dataNascimento == null)?'Não informado':moment(this.atendimento.pessoa.dataNascimento).format('DD/MM/YYYY');
    /* 06 */ var dataTramite: string =  moment(this.atendimento.atendimento.dataHoraTramite).format('DD/MM/YYYY HH:mm:ss');
    /* 07 */ var descricao: string = this.atendimento.atendimento.descricao;
    /* 08 */ var emailPessoa: string = this.atendimento.dadosComplementares.email == ''?'Não informado':this.atendimento.dadosComplementares.email;
    /* 09 */ var emailUnidade: string = (this.atendimento.unidade?.emailEndereco == null)?'':this.atendimento.unidade?.emailEndereco.toString();
    /* 10 */ var logo: string = 'assets/img/brasao-pr.png';
    /* 11 */ var logradouro: string = (this.atendimento.enderecoUnidade?.tipoLogCorreios == null)?'':this.atendimento.enderecoUnidade?.tipoLogCorreios.toString();
    /* 12 */ var nomeBairro: string = (this.atendimento.enderecoUnidade?.nomeBairro == null)?'':this.atendimento.enderecoUnidade?.nomeBairro.toString();
    /* 13 */ var nomeLogradouro: string = (this.atendimento.enderecoUnidade?.nomeLogradouro == null)?'':this.atendimento.enderecoUnidade?.nomeLogradouro.toString();
    /* 14 */ var nomeMae: string = (this.atendimento.pessoa.nomeMae == '')?'Não informado':this.atendimento.pessoa.nomeMae;
    /* 15 */ var nomeSocial: string = (this.atendimento.dadosComplementares.nomeSocial == '' || this.atendimento.dadosComplementares.nomeSocial == null)?'Não informado':this.atendimento.dadosComplementares.nomeSocial;
    /* 16 */ var numCPF: string = (this.atendimento.dadosComplementares.numCpf == '' || this.atendimento.dadosComplementares.numCpf == null)?'Não informado':this.maskPipe.transform(this.atendimento.dadosComplementares.numCpf,'000.000.000-00');
    /* 17 */ var numProtocolo: string = this.maskPipe.transform(this.atendimento.atendimento.numProtocolo,'0000.00.000000-0');
    /* 18 */ var numRg: string = (this.atendimento.dadosComplementares.numRg == '' || this.atendimento.dadosComplementares.numRg == null)?'Não informado':this.atendimento.dadosComplementares.numRg;
    /* 19 */ var numero: string = (this.atendimento.enderecoUnidade?.numero == null)?'':this.atendimento.enderecoUnidade?.numero.toString();
    /* 20 */ var endereco1 : string = logradouro + " " + nomeLogradouro + "," + numero + "," + complemento;
    /* 21 */ var endereco2 : string = "CEP " + CEP + "," + "BAIRRO " + nomeBairro;
    /* 22 */ var telefonePessoa: string = this.atendimento.dadosComplementares.telefone == ''?'Não informado':this.maskPipe.transform(this.atendimento.dadosComplementares.telefone,'(00)0000-0000 || (00)00000-0000');
    /* 23 */ var telefoneUnidade: string = (this.atendimento.enderecoUnidade?.telefone == null)?'':this.atendimento.enderecoUnidade?.telefone.toString();
    /* 24 */ var tipoTramite: string = (this.atendimento.atendimento.tramite == '' || this.atendimento.atendimento.tramite == null)?'':this.atendimento.atendimento.tramite;
    /* 25 */ var unidadeAtual: string = (this.atendimento.unidade?.nomeReduzido == null)?'':this.atendimento.unidade?.nomeReduzido.toString();
    /* 26 */ var uFCidadeMoradia: string = ((this.atendimento.dadosComplementares.ufMoradia == null || this.atendimento.dadosComplementares.ufMoradia==''?'Não informado':this.atendimento.dadosComplementares.ufMoradia)) + " - "
    + ((this.atendimento.dadosComplementares.cidadeMoradia == null || this.atendimento.dadosComplementares.cidadeMoradia == ''?'Não informado':this.atendimento.dadosComplementares.cidadeMoradia));

    /* 27 */
    // Tratamento de nome
    // var nome: string ='';
    // switch (+this.atendimento.pessoa.identificado) {
    //   case 1: nome = this.atendimento.pessoa.nome;break;
    //   case 2: nome = 'Sigilo';break;
    //   case 3: nome = 'Anônimo';break;
    //   default: nome = '';break;
    // }

    /* 27 */
    // Tratamento de nome
    var nome: string ='';
    switch (+this.atendimento.pessoa.identificado) {
      case 1: { switch (this.tipo) {
                  case 'consulta' : nome = (nomeSocial.trim().length > 0 && nomeSocial != 'Não informado')?nomeSocial:this.atendimento.pessoa.nome;break;
                  case 'resumo' : nome = this.atendimento.pessoa.nome;break;
                  default: nome = '';break;
                }
      };break;
      case 2: { switch (this.tipo) {
                  case 'consulta' : nome = 'Sigilo';break;
                  case 'resumo' : nome = this.atendimento.pessoa.nome;break;
                  default: nome = '';break;
                }
      };break;
      case 3: nome = 'Anônimo';break;
      default: nome = '';break;
    }

    /* 28 */
    // Tratamento de sexo
    var sexo: string = '';
    switch (+this.atendimento.dadosComplementares.sexo) {
      case 1: sexo = 'Masculino';break;
      case 2: sexo = 'Feminino';break;
      case 3: sexo = 'Outros';break;
      default: sexo = 'Não informado';break;
    }

    /* 29 */
    // Tratamento de anexos
    var anexos: string = '';
    this.atendimento.atendimento.documentoAtendimentoCollection?.forEach(anexo => {
      anexos = anexo.nomeDocumento + ',' + anexos;
    });
    anexos = anexos.slice(0,anexos.length-1); // Retirar a última vírgula
    anexos = (anexos == '')?'Não informado':anexos;

    /* 30 */
    var situacao: string = 'Em atendimento';
    /* 31 */
    var numProtocoloEnviado: string = (this.atendimento.atendimento.numProtocoloEnviado == '' || this.atendimento.atendimento.numProtocoloEnviado == null)?'':this.atendimento.atendimento.numProtocoloEnviado;


    // Popula o array
    this.d = [ CEP,cidadeFato,complemento,dataCadastro,dataConsulta,dataNascimento,
               dataTramite,descricao,emailPessoa,emailUnidade,logo,logradouro,
               nomeBairro,nomeLogradouro,nomeMae,nomeSocial,numCPF,numProtocolo,
               numRg,numero,endereco1,endereco2,telefonePessoa,telefoneUnidade,
               tipoTramite,unidadeAtual,uFCidadeMoradia,nome,sexo,anexos,situacao,
               numProtocoloEnviado ]

    if (+this.atendimento.pessoa.identificado == 2) {
      // this.d[1] = 'Sigilo';
      // this.d[5] = 'Sigilo';
      // this.d[7] = 'Sigilo';
      // this.d[8] = 'Sigilo';
      // this.d[14] = 'Sigilo';
      // this.d[15] = 'Sigilo';
      // this.d[16] = 'Sigilo';
      // this.d[18] = 'Sigilo';
      // this.d[22] = 'Sigilo';
      // this.d[26] = 'Sigilo';
      // this.d[28] = 'Sigilo';
      // this.d[29] = (anexos == '')?'Não informado':'Sigilo';
      // this.d[30] = 'Sigilo';
    }

    if (+this.atendimento.pessoa.identificado == 3) {
      // this.d[7] = 'Sigilo';
      // this.d[29] = 'Sigilo';
    }

     console.log ('Valores ngOnChanges :'+ this.d)

  }

  goToConsulta() {
      this.cardConsulta = 'none';
      this.router.routeReuseStrategy.shouldReuseRoute = function() { return false; };
      this.router.navigate(['/consultaProtocolo']);
  }

  goToAtendimento() {
      this.cardConsulta = 'none';
      this.router.routeReuseStrategy.shouldReuseRoute = function() { return false; };
      this.router.navigate(['/atendimento']);
  }

  servicePDF() {
    this.dadosAtendimentoService.gerarPDF(this.d,this.tipo);
  }

}
