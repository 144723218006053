import { PerguntaResposta } from './../../../shared/modelos/perguntaResposta';
import { getTestBed } from '@angular/core/testing';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Atendimento } from '../../../shared/modelos/atendimento.model';
import { Observable, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { InformacaoUtil } from './../../../shared/modelos/informacaoUtil';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
//import { Hash } from 'crypto';
//import * as CryptoJS from 'crypto';

@Injectable({
  providedIn: 'root',
})


@Component({
  selector: 'app-perguntas-respostas',
  templateUrl: './perguntas-respostas.component.html',
  styleUrls: ['./perguntas-respostas.component.scss']
})
export class PerguntasRespostasComponent implements OnInit {

  perguntaRespostas!: Observable<PerguntaResposta[]>;
  informacaoUtils!: Observable<InformacaoUtil[]>;
  ipAddress = '';
  informacaoUtil: InformacaoUtil = {
    //idInformacaoUtil?: number;
    idPerguntaResposta: 0,
    resposta: '' ,
    ipUsuario: '' ,
    dataInclusao: new Date
}


  panelOpenState = false;
  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router
  ) {}


  ngOnInit(): void {
    this.getIPAddress();
    this.perguntaRespostas = this.getlistarPerguntaResposta();

  }

  onSubmit(formulario: any) {
    //console.log('Formulário:' );
  }


  private URLlistarPerguntaResposta = `${environment.back_atendimento}/listarPerguntaResposta`;
  private URLsalvarInformacaoUtil= `${environment.back_atendimento}/salvarInformacaoUtil`;

  radioButtonGroupIdentificado(data: MatRadioChange) {
    //console.log('value ' + data.value);
    //console.log('source ' + data.source.id);
    //console.log(data.value.substr(0, 1));
    let utilSimNao = data.source.id.substr(0, 1);
    let utilId = data.source.id.substr(1, 19);
    let utilIpAtual = this.ipAddress;
   // console.log(utilIpAtual);
    if (utilSimNao === 'S') {
      this.informacaoUtil = {
        //idInformacaoUtil?: number;
        idPerguntaResposta:  parseInt(utilId) ,
        resposta: utilSimNao ,
        ipUsuario: utilIpAtual ,
        dataInclusao: new Date
    }
    }else{
      this.informacaoUtil = {
        //idInformacaoUtil?: number;
        idPerguntaResposta:  parseInt(utilId) ,
        resposta: utilSimNao ,
        ipUsuario: utilIpAtual ,
        dataInclusao: new Date
    }
    //  console.log(this.informacaoUtil)
    }

   this.salvarInformacaoUtil(this.informacaoUtil).subscribe({
    next: ret => {
      this.toastr.success('Informação enviada com sucesso.');
      this.informacaoUtil = ret;
   //   console.log(this.informacaoUtil)
    },
    error: msg => {
      this.toastr.error("Erro no envio");
    },
    });

  }

  getlistarPerguntaResposta(): Observable<PerguntaResposta[]> {
    return this.http.get<PerguntaResposta[]>(this.URLlistarPerguntaResposta);
  }

  salvarInformacaoUtil(informacaoUtil: InformacaoUtil): Observable<InformacaoUtil> {
  const a = this.http.post<InformacaoUtil>(this.URLsalvarInformacaoUtil, informacaoUtil).pipe(
    map((obj) => obj),
    catchError((e) => this.errorHandler(e))
  );

  return a;
}



getIPAddress()
{
  // return this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
  //   this.ipAddress = "";

  //  this.ipAddress = res.ip.Hash;
  //  console.log("Ip Cliente:  " + res.ip.Hash);


  // });

}

errorHandler(e: any): Observable<any> {
    return e;
  }

goPlaces() {
  this.router.navigateByUrl("/atendimento");
}
  
  
}
