<form #formulario="ngForm" autocomplete=off (ngSubmit)="onSubmit(formulario)">

  <!-- Componente para título da aplicação -->
  <app-titulo-aplicacao
    texto="MP Atende - Acompanhar o Trâmite do Atendimento">
  </app-titulo-aplicacao>

  <!-- <div class="spinnerSection" *ngIf="progressVisible">
    <label>Aguarde, consultando atendimento...</label>
    <mat-spinner></mat-spinner>
  </div> -->

  <h4 class="spinnerSection" *ngIf="progressVisible">Aguarde, consultando atendimento...</h4>
  <div class="spinnerSection" *ngIf="progressVisible">
      <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
    <!-- <mat-progress-bar mode="buffer" color="warn"></mat-progress-bar> -->
  </div>

  <mat-card class="home mat-elevation-z3" [style.display]="cardInicial">
    <mat-card-content>
      <mat-card-title class="title">Digite o número do protocolo</mat-card-title><br>
      <label for="numProtocolo">Caso já tenha relatado alguma situação ao Ministério Público do Paraná
        e queira acompanhar o andamento, por favor, informe o número do protocolo do atendimento.</label><br><br>
      <mat-form-field class="numProtocolo" appearance="outline">
        <mat-label>Protocolo</mat-label>
        <input matInput type="text" mask="0000.00.000000-0" name="numProtocolo" id="numProtocolo"
        placeholder="0000.00.000000-0" [(ngModel)]=numProtocoloTela required>
      </mat-form-field>
    </mat-card-content>
    <button mat-raised-button (click)="consultar()" type="submit" color="primary">Consultar Atendimento</button>
  </mat-card>

  <!-- Componente para consulta do atendimento -->
  <div id="consultaatendimento" name="consultaatendimento" [style.display]="cardResumo">
    <app-dados-atendimento
      texto = "Situação atual da Solicitação"
      [atendimento] = "atendimento"
      tipo = "consulta">
    </app-dados-atendimento>
  </div>

  <!-- <br>

  <button mat-raised-button (click)="goToConsulta()" color="primary" [style.display]="cardResumo">Consultar outro Atendimento</button> -->

</form>
