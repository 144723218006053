import { Component, OnInit,EventEmitter,ViewChild, ElementRef } from '@angular/core';
import { AtendimentoCadastrarSalvarService } from './atendimento-cadastrar-salvar.service';
import { AtendimentoCadastrarValidarService } from './atendimento-cadastrar-validar.service';
import { Atendimento } from '../../../shared/modelos/atendimento.model';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatRadioChange } from '@angular/material/radio';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EstadosOptions } from '../../../shared/enum/estados.enum';

import * as moment from 'moment';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-atendimento',
  templateUrl: './atendimento-cadastrar.component.html',
  styleUrls: ['./atendimento-cadastrar.component.scss'],
  providers: [
    AtendimentoCadastrarSalvarService,
    AtendimentoCadastrarValidarService,
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})

export class AtendimentoCadastrarComponent implements OnInit {
  //public carrega = "false";
  public progressVisible: boolean = false;

  //Variáveis de controle de fluxo no formulário
  // @ViewChild ('cienciaAnonimato',{ static: true}) cienciaAnonimato? : ElementRef<HTMLElement>;
  cienciaAnonimato : boolean = false;
  continuarAnonimato : number = 0;

  n: number[] = [];

  p: boolean[] = [true,false,false,false,false,false,false,false,false,false,false,true,false];
  //  0 Card 'Início'
  //  1 Card 'Dados cadastrais'
  //  2 Card 'Continua anônimo'
  //  3 Card 'Descrição do motivo'
  //  4 Card 'Resumo do atendimento'
  //  5 Card 'Confirmação do sigilo'
  //  6 Card 'botão de confirmar'
  //  7 DIV 'rg'
  //  8 Card 'Confirmação de sem Sigilo'
  //  9 Card 'Mensagem de recesso'
  // 10 Card 'Mensagem de indisponibilidade'
  // 11 Card 'Mensagem de funcionamento'
  // 12 DIV 'Nacionalidade'
  // 13 Card 'Mensagem de atencao'
  //Variáveis de controle de fluxo no formulário

  b8 = 'none';  // DIV pesquisa de satisfação
  b9 = 'block'; // DIV pesquisa de satisfação
  d0 = false;   // Habilitar a opção de selecionar sigilo
  d1 = false;   // Habilitar a opção de continuar anônimo
  d2 = true;    // Habilitar a opção de 'procurou anteriormente'
  d3 = false;   // Habilitar a opção de 'cpf'
  d4 = false;   // Habilitar a opção de 'RG'

  mba = true;   // Mostrar botão avançar
  mbv = false;  // Mostrar botão voltar
  hba = false;  // Habilitar botão avançar
  hbv = false;  // Habilitar botão voltar
  ant = 0;      // Número da página anterior
  atu = 0;      // Número da página atual

  flagNaoInformaCpf = false;
  flagNaoInformaRg = false;
  flagCpfValido = false;
  flagEmailValido = false;
  flagSelecaoPais = false;

  atendimento: Atendimento = {
    atendimento: {
      sigilo: 0,
      descricao: '',
      areaAssunto: 0,
      procurouMP: 0,
      possuiNumAtendimento: 0,
      numAtendAnterior: '',
      numProtocolo: '',
      cidadeFato: '',
      dataHoraCadastro: '',
      documentoAtendimentoCollection: [],
    },

    pessoa: {
      nome: '',
      identificado: 0,
      tratadoNomeSocial: '',
      dataNascimento: '',
      nomeMae: '',
    },

    dadosComplementares: {
      nomeSocial: '',
      numCpf: '',
      cidadeMoradia: '',
      ufMoradia: '',
      telefone: '',
      email: '',
      sexo: '',
      numRg: '',
      ufRg: '',
      // idPais: 105,
      idPais: undefined,
    },
  };

  constructor(
    public salvar: AtendimentoCadastrarSalvarService,
    public validar: AtendimentoCadastrarValidarService,
    // private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    //this.p[13] = true;
    // Mensagem de recesso fixa
    // this.p[0] = false;this.p[9] = true;this.mbv = false;this.mba = false;

    // Mensagem de recesso por período
    // if (moment().isBetween('2022-12-20 00:00:00','2023-01-06 23:59:00')) {
    //   this.p[0] = false;this.p[9] = true;this.mbv = false;this.mba = false;
    // } else {
    //   this.p[0] = true;this.p[9] = false;this.mbv = false;this.mba = true;
    // }

    // Mensagem de indisponibilidade fixa
    // this.p[0] = false;this.p[10] = true;this.mbv = false;this.mba = false;

    // Mensagem de indisponibilidade por período
    // if (moment().isBetween('2023-05-08 12:49:00','2023-05-08 12:51:00')) {
    //   this.p[0] = false;this.p[10] = true;this.mbv = false;this.mba = false;
    // } else {
    //   this.p[0] = true;this.p[10] = false;this.mbv = false;this.mba = true;
    // }
  }

  //Teste de submit do form
  onSubmit(formulario: any) {
  //   console.log('Formulário:' + formulario);
  }

  estadosOptions = EstadosOptions;

  // Habilitar a escolha do tipo de sigilo
  // escolhaSigilo() {
    // if (this.atendimento.atendimento.cidadeFato == '') {
    //   this.d0 = true;
    // } else {
    //   // console.log('Cidade: ' + this.atendimento.atendimento.cidadeFato);
    //   this.d0 = false;
    // }
  //   this.atendimento.atendimento.cidadeFato == ''?this.d0 = true:this.d0 = false
  // }

  // Habilitar campo RG e seleção de estado se o CPF não for informado
  habilitaCampoRg(evento : any) {
    if (evento.target.checked) {
      this.p[7]=true;
      this.d3 = true;
      this.atendimento.dadosComplementares.numCpf = '';
      this.flagCpfValido = true;

      // Apagar o RG e estado do RG se o usuário 'desmarcar' o campo 'Não informado do CPF'
      this.atendimento.dadosComplementares.numRg = '';
      this.atendimento.dadosComplementares.ufRg = '';
    }
    else {
      this.p[7]=false;
      this.d3 = false;
      this.flagCpfValido = false;
    }
  }

  // Desabilitar campo RG e seleção de estado se o checkbox estiver marcado
  desabilitaCampoRg(evento : any) {
    if (evento.target.checked) {
      this.d4 = true;
      this.atendimento.dadosComplementares.numRg = '';
      this.atendimento.dadosComplementares.ufRg = '';
      this.flagNaoInformaRg = true;
    } else {
      this.d4 = false;
      this.flagNaoInformaRg = false;
    }
  }

  // Habilitar o botão Enviar na tela descrição do motivo'
  // habilitaBotaoEnviar(evento : any) {
  //   if (evento.target.value.trim().length > 0) {
  //     this.d2 = false;
  //   }
  //   else {
  //     this.d2 = true;
  //   }
  // }

  //--------------------------------------------
  //Controle do fluxo - Radio Buttons e Checkbox
  //--------------------------------------------

  // Deseja identificar-se?
  radioButtonGroupIdentificado(data: MatRadioChange) {
    // 'Identificado?' (sim)
    // Mostrar card 'Dados cadastrais'
    // Mostrar card 'Descrição do motivo'
    // if (data.value === '1' || data.value === '2') {
    //     // this.b0 = 'none';
    //     // this.p[1]=true;
    //     this.d0 == true?this.n2 = false:this.n2 = true;
    // }
    // 'Identificado?' (não)
    // Mostrar card 'Pergunta se continua anônimo'
    // if (data.value === '3') {
    //     // this.b0 = 'none';
    //     // this.p[2]=true;
    // }
    (data.value == 0 || this.atendimento.atendimento.cidadeFato == '')?this.hba = false:this.hba = true;
  }

  // 'Estou ciente'
  // Ativa 'Deseja coninuar anônimo?'
  checkButtonCienciaAnonimato(data: MatCheckboxChange ) {
    if (data.checked) {
      // this.d1 = false;
      this.cienciaAnonimato = true;
      (this.cienciaAnonimato && this.continuarAnonimato != 0)?this.hba=true:this.hba=false;
    } else {
        // this.d1 = true;
        this.cienciaAnonimato = false;
        this.hba=false;
      }
  }

  // 'Deseja continuar anônimo?'
  radioButtonGroupAnonimo(data: MatRadioChange) {
    // 'Continuar anônimo?' (sim))
    // Mostrar card 'Descrição do motivo'
    // if (data.value === "1"){
      //   this.p[1]=false;
      //   this.p[2]=false;
      //   this.p[3]=true;
      // }
      // // // 'Continuar anônimo?' (não)
      // // Mostrar card 'Tipo do Sigilo'
      // if (data.value === "2"){
        //   this.p[1]=false;
        //   this.p[2]=false;
        //   this.p[5]=true;
        // }
    this.continuarAnonimato = data.value;
    (this.cienciaAnonimato && this.continuarAnonimato != 0)?this.hba=true:this.hba=false;
  }

  radioButtonGroupSemSigilo(data: MatRadioChange) {
    if (data.value === '1')  {
      this.hba=true;
    } else this.hba=false;
  }

  // 'Tipo Sigilo' (sim - opção 1 ou opção 2)
  // Mostrar card 'Dados cadastrais'
  // Mostrar card 'Descrição do motivo'
  radioButtonGroupSigilo(data: MatRadioChange){
    if (data.value === '1' || data.value === '2')  {
      // this.p[1]=true;
      // this.p[5]=false;
      this.hba=true;
    }
  }

  confirmaDadosCadastrais() {
    if (this.validar.validaDadosCadastrais(this.atendimento,this.flagNaoInformaCpf,
                                           this.flagNaoInformaRg,this.flagCpfValido,
                                           this.flagEmailValido,this.flagSelecaoPais)) {
      // this.p[1]=false;
      // this.p[3]=true;
      return true;
    } else return false;
  }

  criarAtendimento(): void {
    //this.p[13] = true;
    this.p[3]=false;
    this.p[6]=false;
    this.p[7]=false;
    this.d3 = false;
    this.d4 = false;
    this.progressVisible = true;

    //     this.salvar.salvarAtendimento(this.atendimento).subscribe((ret) => {
    //      this.progressVisible = false;
    //      this.toastr.success('Atendimento enviado com sucesso.');
    // this.salvar.showMessage('Atendimento enviado com sucesso.');
    // console.log(ret);
    // Copiar o objeto de retorno para o objeto
    //      this.atendimento = ret,
    //      (e: { error: { message: any; }; }) => console.log("dentro d")
    //   });

    this.salvar.salvarAtendimento(this.atendimento).subscribe({
      next: (ret) => {
        this.p[4]=true;
        this.atendimento = ret;
        this.progressVisible = false;
        this.toastr.success(this.atendimento.atendimento.enviadoEpromp === 'S' ? 'Atendimento enviado com sucesso.' : 'Atendimento ja registrado.');
      },
      error: (msg) => {
        this.p[4]=false;
        this.toastr.error('Ocorreu um erro ');
        this.progressVisible = false;
      },
    });
  }

  // Tratamento de eventos de componentes - Selecionar cidade
  selecionado(evento: any) {
    this.atendimento.atendimento.cidadeFato = evento.cidade;
    // (this.atendimento.atendimento.cidadeFato == '')?this.d0 = true:this.d0 = false;
    (this.atendimento.pessoa.identificado == 0 || this.atendimento.atendimento.cidadeFato == '')?this.hba = false:this.hba = true;
    // this.escolhaSigilo();
  }

  // Tratamento de eventos de componentes - Inclusão/Exclusão de documentos
  anexos(evento: any) {
    // console.log(
    //   'Length registro de retorno do componente: ' +
    //     evento.registroAlterado.atendimento.documentoAtendimentoCollection
    //       .length
    // );
    this.atendimento.atendimento.documentoAtendimentoCollection =
      evento.registroAlterado.atendimento.documentoAtendimentoCollection;
    // console.log(
    //   'Length registro atual: ' +
    //     this.atendimento.atendimento.documentoAtendimentoCollection?.length
    // );
  }

  // Tratamento de eventos de componentes - Selecionar cidade e estado
  ufSelecionada(evento: any) {
    this.atendimento.dadosComplementares.ufMoradia = evento.estado;
  }
  cidadeSelecionada(evento: any) {
    this.atendimento.dadosComplementares.cidadeMoradia = evento.cidade;
  }

  // Tratamento de eventos de componentes - Selecionar sexo
  sexoSelecionado(evento: any) {
    this.atendimento.dadosComplementares.sexo = evento.sexo;
  }

  // Tratamento de eventos de componentes - Selecionar estado
  estadoSelecionado(evento: any) {
     this.atendimento.dadosComplementares.ufRg = evento.estado;
  }

  // Tratamento de eventos de componentes - Digitar CPF
  cpfDigitado(evento: any) {
    if (!this.validar.cpfValido(evento.target.value)) {
      this.flagCpfValido = false;
    }
    else {
      this.flagCpfValido = true;
    }
  }

  // Tratamento de eventos de componentes - Digitar E-mail
  emailDigitado(evento: any) {
    if (!this.validar.emailValido(evento.target.value)) {
      this.flagEmailValido = false;
    }
    else {
      this.flagEmailValido = true;
    }
  }

  // Tratamento de eventos de componentes - Ciência do recesso
  cienciaRecesso(evento: any) {
    this.p[0] = true;this.p[9] = false;this.mba = true;
  }

  avancar(x:number,y:number){
    this.p[x] = false;
    this.atu = y;
    this.p[this.atu] = true;
    this.n.push(x);
    var rota : string = '';
    (this.n.length == 0)?rota = '0':rota = this.n + "," + this.atu;
    console.log("página(s): " + rota);
    // console.log("identificado : " +this.atendimento.pessoa.identificado);
  }

  voltar(x:number) {
    this.p[x] = false;
    this.atu = this.n[this.n.length-1];
    this.p[this.atu] = true;
    this.n.pop();
    var rota : string = '';
    (this.n.length == 0)?rota = '0':rota = this.n + "," + this.atu;
    console.log("página(s): " + rota);
    // console.log("identificado : " +this.atendimento.pessoa.identificado);
  }

  inicializa(r:number) {
    switch (r) {

      case 0: {
                this.atendimento.atendimento.cidadeFato='';
                this.atendimento.pessoa.identificado=0;
                this.mbv=false;this.hbv=false;this.mba=true;this.hba=false;
      };break;

      case 1: {
                this.atendimento.pessoa.nome='';
                this.atendimento.dadosComplementares.nomeSocial='';
                this.atendimento.dadosComplementares.sexo='';
                this.atendimento.dadosComplementares.numCpf='';
                this.atendimento.dadosComplementares.numRg='';
                this.atendimento.dadosComplementares.ufRg='';
                this.atendimento.pessoa.dataNascimento='';
                this.atendimento.pessoa.nomeMae='';
                this.atendimento.dadosComplementares.cidadeMoradia='';
                this.atendimento.dadosComplementares.ufMoradia='';
                this.atendimento.dadosComplementares.telefone='';
                this.atendimento.dadosComplementares.email='';
                this.atendimento.dadosComplementares.idPais=undefined;
                this.flagNaoInformaCpf = false;
                this.flagNaoInformaRg = false;
                this.flagSelecaoPais = false;
                this.p[12] = false;
                this.p[7] = false;
                this.mbv=true;this.hbv=true;this.mba=true;this.hba=true;
      };break;

      case 2: {
                this.atendimento.pessoa.identificado=3;
                this.cienciaAnonimato = false;
                this.continuarAnonimato = 0;
                this.mbv=true;this.hbv=true;this.mba=true;this.hba=false;
      };break;

      case 3: {
                this.atendimento.atendimento.descricao = '';
                this.atendimento.atendimento.documentoAtendimentoCollection = [];
                this.atendimento.atendimento.numAtendAnterior = '';
                this.mbv=true;this.hbv=true;this.mba=true;this.hba=true;
       };break;

      case 5: {
                this.atendimento.pessoa.identificado = 0;
                this.mbv=true;this.hbv=true;this.mba=true;this.hba=false;
      };break;

      case 8: {
                if (this.ant == 0) this.atendimento.pessoa.identificado = 0;
                this.mbv=true;this.hbv=true;this.mba=true;this.hba=false;
      };break;

      default:;break;
    }
  }

  contexto(paginaAtual:number,b:number) {

    this.ant = this.n[this.n.length-1];

    // Botão voltar
    if (b == 1) {

      switch (paginaAtual) {

        // 0 (início)
        case 0: {
        };break;

        // 1 (dados cadastrais)
        //  => 0 (início)
        //  => 5 (escolha o sigilo)
        //  => 8 (confirma sem sigilo)
        case 1: {
                  if (this.ant == 0) { this.inicializa(1);this.inicializa(0) };
                  if (this.ant == 5) { this.inicializa(1);this.inicializa(5) };
                  if (this.ant == 8) { this.inicializa(1);this.inicializa(8) };
                  this.voltar(1);
        };break;

        // 2 (continua anônimo)
        //  => 0 (início)
        case 2: {
                  this.inicializa(2);this.inicializa(0);this.voltar(2)
        };break;

        // 3 (descrição do motivo)
        //  => 1 (dados cadastrais)
        //  => 2 (continua anônimo)
        case 3: {
                  if (this.ant == 1) { this.inicializa(3);this.inicializa(1); }
                  if (this.ant == 2) { this.inicializa(3);this.inicializa(2); }
                  this.voltar(3);
        };break;

        // 5 (escolha o sigilo)
        //  => 2 (continua anônimo)
        case 5: {
                  this.inicializa(5);this.inicializa(2);this.voltar(5);
        };break;

        // 8 (confirma sem sigilo)
        //  => 0 (início)
        case 8: {
                  if (this.ant == 0) { this.inicializa(8);this.inicializa(0) };
                  if (this.ant == 5) { this.inicializa(8);this.inicializa(5) };
                  this.voltar(8);
        };break;

        default:;break;
      }
    }

    // Botão avançar
    if (b == 2) {
      switch (paginaAtual) {

        // 0 (início)
        //  => 1 (dados cadastrais)
        //  => 2 (continua anônimo)
        case 0: {
                  if (this.atendimento.pessoa.identificado == 1) { this.inicializa(8);this.avancar(0,8) };
                  if (this.atendimento.pessoa.identificado == 2) { this.inicializa(1);this.avancar(0,1) };
                  if (this.atendimento.pessoa.identificado == 3) { this.inicializa(2);this.avancar(0,2) };
        };break;

        // 1 (dados cadastrais)
        //  => 3 (descrição do motivo)
        case 1: {
                  if (this.confirmaDadosCadastrais()) { this.inicializa(3);this.avancar(1,3); }
        };break;

        // 2 (continua anônimo)
        //  => 3 (descrição do motivo)
        //  => 5 (escolha o sigilo)
        case 2: {
                  if (this.continuarAnonimato == 1) { this.inicializa(3);this.avancar(2,3); };
                  if (this.continuarAnonimato == 2) { this.inicializa(5);this.avancar(2,5); };
        };break;

        // 3 (descrição do motivo)
        //  => Fim
        case 3: {
                  var descricao : string = this.atendimento.atendimento.descricao;
                  if (this.validar.existeDescricaoMotivo(descricao) && this.validar.minimoPalavras(descricao)) {
                    this.mbv=false;this.hbv=false;this.mba=false;this.hba=false;
                    this.criarAtendimento();
                  }
        };break;

        // 5 (escolha o sigilo)
        //  => 1 (dados cadastrais)
        case 5: {
                  // this.inicializa(1);this.avancar(5,1);
                  if (this.atendimento.pessoa.identificado == 1) { this.inicializa(8);this.avancar(5,8) };
                  if (this.atendimento.pessoa.identificado == 2) { this.inicializa(1);this.avancar(5,1) };
        };break;

        // 8 (confirma o sigilo)
        //  => 1 (dados cadastrais)
        case 8: {
                  this.inicializa(1);this.avancar(8,1);
        };break;

        default:;break;
      }
    }
  }

  telaPesquisa(): void {
    this.b8 = 'block';
    this.b9 = 'none';
  }


  // Habilitar selecionar pais
  habilitaPais(evento : any) {
    if (evento.target.checked) {
      this.p[12]=true;
      this.flagSelecaoPais = true;
      this.atendimento.dadosComplementares.idPais = undefined;
    }
    else {
      this.p[12]=false;
      this.atendimento.dadosComplementares.idPais = 105;
      this.flagSelecaoPais = false;
    }
  }

  paisSelecionado(x : number) {
    console.log('País => ' + x);
    this.atendimento.dadosComplementares.idPais = x;
  }
}
