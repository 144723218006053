import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
// import { KeycloakService } from 'mppr-keycloak';
import { Observable } from 'rxjs';

const baseUrl = environment.back_google_api_drive;

@Injectable({
  providedIn: 'root',
})
export class GoogleDriveService {
  constructor(
    // private keyClockService: KeycloakService,
    private http: HttpClient
  ) {}

  public getUrlGoogleDriveUpload(): string {
    return `${baseUrl}/upload/`;
  }

  download(id: any, converte = false): Observable<any> {
    const parametros = new HttpParams().append(
      'converte',
      converte ? 'true' : 'false'
    );

    return this.http.get(`${baseUrl}/download/${id}`, {
      responseType: 'blob',
      withCredentials: true,
      params: parametros,
    });
  }

  upload(file: any): Observable<any> {
    return this.http.post(this.getUrlGoogleDriveUpload(), file, {
      responseType: 'text',
    });
  }

  adicionarPermissao(id: any, role: any): Observable<any> {
    return this.http.post(`${baseUrl}/permissao/${id}?role=${role}`, null);
  }

  removerPermissao(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/permissao/${id}`);
  }

  removerArquivoDrive(idDrive: string): Observable<any> {
    return this.http.delete(`${baseUrl}/${idDrive}`);
  }
}
