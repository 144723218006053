import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { sexoOptions } from '../../enum/sexo.enum';

@Component({
  selector: 'app-selecionar-sexo',
  templateUrl: './selecionar-sexo.component.html',
  styleUrls: ['./selecionar-sexo.component.scss'],
})
export class SelecionarSexoComponent implements OnInit {
  @Input() texto: string | undefined;
  @Output() sexoSelecionado = new EventEmitter();

  sexoOptions = sexoOptions;

  constructor() {}

  ngOnInit(): void {}

  itemSexoSelecionado(sexo: any) {
    this.sexoSelecionado.emit({ sexo: sexo });
  }
}
