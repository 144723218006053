import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { SelecionarEstadoCidadeService } from './selecionar-estado-cidade.service';
import { Cidade } from '../../modelos/cidade.model';
import { Observable } from 'rxjs';
import { EstadosOptions } from "../../enum/estados.enum"

@Component({
  selector: 'app-selecionarestadocidade',
  templateUrl: './selecionar-estado-cidade.component.html',
  styleUrls: ['./selecionar-estado-cidade.component.scss'],
  providers: [SelecionarEstadoCidadeService],
})
export class SelecionarEstadoCidadeComponent implements OnInit {

  @Input() texto: string | undefined;
  @Output() ufSelecionada = new EventEmitter();
  @Output() cidadeSelecionada = new EventEmitter();

  // ufs!: string[];
  cidadesMoradia!: Observable<Cidade[]>;
  estadosOptions = EstadosOptions;
  d0 = 'true';

  constructor(
    private selecionarEstadoCidadeService: SelecionarEstadoCidadeService
  ) {}

  ngOnInit(): void {
    // this.ufs = this.selecionarEstadoCidadeService.getUfs();
    // console.log(this.ufs);
  }

  itemUfMoradia(ufMoradia: any) {
    this.cidadesMoradia = this.selecionarEstadoCidadeService.getCidadesMoradia(ufMoradia);
    if (ufMoradia === '') {
      // console.log('Desabilitar combo cidade');
      this.d0 = 'true';
    } else {
      // console.log('Habilitar combo cidade');
      // this.cidadesMoradia = this.selecionarEstadoCidadeService.getCidadesMoradia(ufMoradia);
      this.d0 = 'false';
      this.ufSelecionada.emit({ estado: ufMoradia });
    }
  }

  itemCidadeMoradia(cidadeMoradia: any) {
    this.cidadeSelecionada.emit({ cidade: cidadeMoradia });
  }
}
