


import { Component, Input, OnInit } from '@angular/core';
import { PesquisaSatPerguntas} from '../../modelos/pesquisaSatPerguntas.model';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map, Observable, EMPTY } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MatRadioChange } from '@angular/material/radio';
import { PesquisaSatRespostas } from '../../modelos/pesquisaSatRespostas.model';
import { Atendimento } from '../../modelos/atendimento.model';



@Component({
  selector: 'app-pesquisa-satisfacao',
  templateUrl: './pesquisa-satisfacao.component.html',
  styleUrls: ['./pesquisa-satisfacao.component.scss']
})

export class PesquisaSatisfacaoComponent implements OnInit {
  @Input() registroAtual!: Atendimento;
  card = 'block';
  sugestao: string = "";
  pesquisaSatPerguntas: Observable<PesquisaSatPerguntas[]> = this.getlistarPesquisaSatPerguntas();
  pesquisaSatRespostasLista: PesquisaSatRespostas [] = [];
  pesquisaSatRespostas: PesquisaSatRespostas  =
  //  idPesquisaSatRespostas?: number;
    {idPesquisaSatPerguntas: 0,
    descSugestao: '',
    criterio: 0,
    dataInclusao: new Date,
    ipUsuario: '',
    recomenda: '',
    usarNovamente: '',
    descPesquisaSatPerguntas: '',
    numProtocolo: ''
};


  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private toastr: ToastrService,

  ) {}

  ngOnInit(): void {
    this.pesquisaSatPerguntas = this.getlistarPesquisaSatPerguntas();
    this.pesquisaSatPerguntas.pipe;
    this.setandoListaPesquisa();

  }
  onSubmit(formulario: any) {
    //console.log('Formulário:' );
  }

  private URL= `${environment.back_atendimento}/listarPesquisaSatPerguntas`;
  private URLNome = `${environment.back_atendimento}/listarPesquisaSatPerguntasPorNome?nomePesquisaSatPergunta=Pesquisa de Satisfação`;
  private URLPesquisaSatRespostas= `${environment.back_atendimento}/salvarPesquisaSatRespostas`;

  getlistarPesquisaSatPerguntas(): Observable<PesquisaSatPerguntas[]> {
    return this.http.get<PesquisaSatPerguntas[]>(this.URLNome);
  }

  radioButtonGroupIdentificado(data: MatRadioChange) {
    this.pesquisaSatRespostas  =
  //  idPesquisaSatRespostas?: number;
    {idPesquisaSatPerguntas: 0,
    descSugestao: '',
    criterio: 0,
    dataInclusao: new Date,
    ipUsuario: '',
    recomenda: '',
    usarNovamente: '',
    descPesquisaSatPerguntas: '',
    numProtocolo: this.registroAtual.atendimento.numProtocolo
  };
/*
    if( data.source.name == 'riNecessidade'){

      this.pesquisaSatRespostas.criterio = data.value;
      this.pesquisaSatRespostas.descPesquisaSatPerguntas = 'Ao atendimento das minhas necessidades';
      this.pesquisaSatRespostas.idPesquisaSatPerguntas = 1;

      let temNaLista: boolean  = true;
      for (let i = 0; i < this.pesquisaSatRespostasLista.length; i++) {
        if (this.pesquisaSatRespostasLista[i].idPesquisaSatPerguntas == 1) {
          this.pesquisaSatRespostasLista[i].criterio = data.value;
          temNaLista = false;
          break;
        }
      }
      if(temNaLista==true){
        this.pesquisaSatRespostasLista.push(this.pesquisaSatRespostas);
      }

    }

    if( data.source.name == 'riDesempenho'){
      this.pesquisaSatRespostas.criterio = data.value;
      this.pesquisaSatRespostas.descPesquisaSatPerguntas = 'Desempenho';
      this.pesquisaSatRespostas.idPesquisaSatPerguntas = 2;

      let temNaLista: boolean  = true;
      for (let i = 0; i < this.pesquisaSatRespostasLista.length; i++) {
        if (this.pesquisaSatRespostasLista[i].idPesquisaSatPerguntas == 2) {
          this.pesquisaSatRespostasLista[i].criterio = data.value;
          temNaLista = false;
          break;
        }
      }
      if(temNaLista==true){
        this.pesquisaSatRespostasLista.push(this.pesquisaSatRespostas);
      }

    }

    if( data.source.name == 'riFacilidade'){
      this.pesquisaSatRespostas.criterio = data.value;
      this.pesquisaSatRespostas.descPesquisaSatPerguntas = 'À facilidade de uso';
      this.pesquisaSatRespostas.idPesquisaSatPerguntas = 3;

      let temNaLista: boolean  = true;
      for (let i = 0; i < this.pesquisaSatRespostasLista.length; i++) {
        if (this.pesquisaSatRespostasLista[i].idPesquisaSatPerguntas == 3) {
          this.pesquisaSatRespostasLista[i].criterio = data.value;
          temNaLista = false;
          break;
        }
      }
      if(temNaLista==true){
        this.pesquisaSatRespostasLista.push(this.pesquisaSatRespostas);
      }

    }

    if( data.source.name == 'riEstabilidade'){
      this.pesquisaSatRespostas.criterio = data.value;
      this.pesquisaSatRespostas.descPesquisaSatPerguntas = 'À estabilidade (ausência de interrupções frequentes)';
      this.pesquisaSatRespostas.idPesquisaSatPerguntas = 4;

      let temNaLista: boolean  = true;
      for (let i = 0; i < this.pesquisaSatRespostasLista.length; i++) {
        if (this.pesquisaSatRespostasLista[i].idPesquisaSatPerguntas == 4) {
          this.pesquisaSatRespostasLista[i].criterio = data.value;
          temNaLista = false;
          break;
        }
      }
      if(temNaLista==true){
        this.pesquisaSatRespostasLista.push(this.pesquisaSatRespostas);
      }

    }

    if( data.source.name == 'riQualidade'){
      this.pesquisaSatRespostas.criterio = data.value;
      this.pesquisaSatRespostas.descPesquisaSatPerguntas = 'À qualidade das informações oferecidas';
      this.pesquisaSatRespostas.idPesquisaSatPerguntas = 5;

      let temNaLista: boolean  = true;
      for (let i = 0; i < this.pesquisaSatRespostasLista.length; i++) {
        if (this.pesquisaSatRespostasLista[i].idPesquisaSatPerguntas == 5) {
          this.pesquisaSatRespostasLista[i].criterio = data.value;
          temNaLista = false;
          break;
        }
      }
      if(temNaLista==true){
        this.pesquisaSatRespostasLista.push(this.pesquisaSatRespostas);
      }

    }
*/
    if( data.source.name == 'riRecomenda'){
/*      this.pesquisaSatRespostas.recomenda = data.value;
      this.pesquisaSatRespostas.descPesquisaSatPerguntas = 'Você recomendaria o MP Atende';
      this.pesquisaSatRespostas.idPesquisaSatPerguntas = 5;

      let temNaLista: boolean  = true;
      for (let i = 0; i < this.pesquisaSatRespostasLista.length; i++) {
        if (this.pesquisaSatRespostasLista[i].idPesquisaSatPerguntas == 6) {
          this.pesquisaSatRespostasLista[i].criterio = data.value;
          temNaLista = false;
          break;
        }
      }
      if(temNaLista==true){
        this.pesquisaSatRespostasLista.push(this.pesquisaSatRespostas);
      }
*/
      this.pesquisaSatRespostas  =
      //  idPesquisaSatRespostas?: number;
        {idPesquisaSatPerguntas: 5,
        descSugestao: '',
        criterio: 0,
        dataInclusao: new Date,
        ipUsuario: '',
        recomenda: data.value,
        usarNovamente: '',
        descPesquisaSatPerguntas: 'Você recomendaria o MP Atende',
        numProtocolo: this.registroAtual.atendimento.numProtocolo
        };

        this.pesquisaSatRespostasLista[5] = this.pesquisaSatRespostas;
    }

    if( data.source.name == 'riUsaria'){
  /*    this.pesquisaSatRespostas.usarNovamente  = data.value;
      this.pesquisaSatRespostas.descPesquisaSatPerguntas = 'Você usaria novamente o MP Atende';
      this.pesquisaSatRespostas.idPesquisaSatPerguntas = 6;

      let temNaLista: boolean  = true;
      for (let i = 0; i < this.pesquisaSatRespostasLista.length; i++) {
        if (this.pesquisaSatRespostasLista[i].idPesquisaSatPerguntas == 7) {
          this.pesquisaSatRespostasLista[i].criterio = data.value;
          temNaLista = false;
          break;
        }
      }
      if(temNaLista==true){
        this.pesquisaSatRespostasLista.push(this.pesquisaSatRespostas);
      }
*/
      this.pesquisaSatRespostas  =
      //  idPesquisaSatRespostas?: number;
        {idPesquisaSatPerguntas: 6,
        descSugestao: '',
        criterio: 0,
        dataInclusao: new Date,
        ipUsuario: '',
        recomenda: data.value,
        usarNovamente: '',
        descPesquisaSatPerguntas: 'Você usaria novamente o MP Atende',
        numProtocolo: this.registroAtual.atendimento.numProtocolo
        };

        this.pesquisaSatRespostasLista[6] = this.pesquisaSatRespostas;

    }

  }

 /* enviar(): void {
    let semErro: boolean  = true;
    if(this.sugestao  != ''){
      this.pesquisaSatRespostas  =
      //  idPesquisaSatRespostas?: number;
        { idPesquisaSatPerguntas: 0,
          descSugestao: this.sugestao,
          criterio: 0,
          dataInclusao: new Date,
          ipUsuario: '',
          recomenda: '',
          usarNovamente: '',
          descPesquisaSatPerguntas: '',
          numProtocolo: this.registroAtual.atendimento.numProtocolo
        };
        this.pesquisaSatRespostasLista.push(this.pesquisaSatRespostas);
    }



    if(semErro){
//      this.pesquisaSatRespostasLista.sort((a,b)=> (a.idPesquisaSatRespostas!  < b.idPesquisaSatRespostas!) ? -1 : 1);
      for (let i = 0; i < this.pesquisaSatRespostasLista.length; i++) {
        this.pesquisaSatRespostas = this.pesquisaSatRespostasLista[i];
        this.salvarPesquisaSatRespostas(this.pesquisaSatRespostas).subscribe({
        next: ret => {
        // this.toastr.success('Informação enviada com sucesso.');
          this.pesquisaSatRespostas = ret;
        },
        error: msg => {
          semErro = false;
          this.toastr.error("Erro no envio");
        },

        });
      }

      if(semErro)
        this.toastr.success('Pesquisa enviada com sucesso.');
      else
        this.toastr.error("Erro no envio");

      this.card = 'none';
    }
  }*/

  salvarPesquisaSatRespostas(pesquisaSatRespostas: PesquisaSatRespostas): Observable<PesquisaSatRespostas> {
    const a = this.http.post<PesquisaSatRespostas>(this.URLPesquisaSatRespostas, pesquisaSatRespostas).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
      return a;

 }

// getTotalPalavras(texto: string) {
//  return texto.trim().split(/\s+/).length;
//}

 errorHandler(e: any): Observable<any> {
  return e;
  }

  rating = 0;
  ratingDesempenho = 0;
  ratingFacilidade = 0;
  ratingEstabilidade = 0;
  ratingQualidade = 0;

  setRating(rating: number): void {
    this.rating = rating;
    this.pesquisaSatRespostas  =
    //  idPesquisaSatRespostas?: number;
    {idPesquisaSatPerguntas: 0,
    descSugestao: '',
    criterio: this.rating,
    dataInclusao: new Date,
    ipUsuario: '',
    recomenda: '',
    usarNovamente: '',
    descPesquisaSatPerguntas: 'Ao atendimento das minhas necessidades',
    numProtocolo: this.registroAtual.atendimento.numProtocolo
    };

    this.pesquisaSatRespostasLista[0] = this.pesquisaSatRespostas;
  }

  setRatingDesempenho(ratingDesempenho: number): void {
      this.ratingDesempenho = ratingDesempenho;
      this.pesquisaSatRespostas  =
      //  idPesquisaSatRespostas?: number;
      {idPesquisaSatPerguntas: 1,
      descSugestao: '',
      criterio: this.ratingDesempenho,
      dataInclusao: new Date,
      ipUsuario: '',
      recomenda: '',
      usarNovamente: '',
      descPesquisaSatPerguntas: 'Desempenho',
      numProtocolo: this.registroAtual.atendimento.numProtocolo
      };
      this.pesquisaSatRespostasLista[1] = this.pesquisaSatRespostas;
  }

  setRatingFacilidade(ratingFacilidade: number): void {
      this.ratingFacilidade = ratingFacilidade;
      this.pesquisaSatRespostas  =
      //  idPesquisaSatRespostas?: number;
      {idPesquisaSatPerguntas: 2,
      descSugestao: '',
      criterio: this.ratingFacilidade,
      dataInclusao: new Date,
      ipUsuario: '',
      recomenda: '',
      usarNovamente: '',
      descPesquisaSatPerguntas: 'Facilidade de Uso',
      numProtocolo: this.registroAtual.atendimento.numProtocolo
      };
      this.pesquisaSatRespostasLista[2] = this.pesquisaSatRespostas;
  }

  setRatingEstabilidade(ratingEstabilidade: number): void {
      this.ratingEstabilidade = ratingEstabilidade;
      this.pesquisaSatRespostas  =
      //  idPesquisaSatRespostas?: number;
      {idPesquisaSatPerguntas: 3,
      descSugestao: '',
      criterio: this.ratingEstabilidade,
      dataInclusao: new Date,
      ipUsuario: '',
      recomenda: '',
      usarNovamente: '',
      descPesquisaSatPerguntas: 'À estabilidade (ausência de interrupções frequentes)',
      numProtocolo: this.registroAtual.atendimento.numProtocolo
      };
      this.pesquisaSatRespostasLista[3] = this.pesquisaSatRespostas;
  }

  setRatingQualidade(ratingQualidade: number): void {
      this.ratingQualidade = ratingQualidade;
      this.pesquisaSatRespostas  =
      //  idPesquisaSatRespostas?: number;
      {idPesquisaSatPerguntas: 4,
      descSugestao: '',
      criterio: this.ratingQualidade,
      dataInclusao: new Date,
      ipUsuario: '',
      recomenda: '',
      usarNovamente: '',
      descPesquisaSatPerguntas: 'À qualidade das informações oferecidas',
      numProtocolo: this.registroAtual.atendimento.numProtocolo
      };
      this.pesquisaSatRespostasLista[4] = this.pesquisaSatRespostas;
  }


  enviar(): void {
      let semErro: boolean  = true;

      if(this.sugestao  != ''){
        this.pesquisaSatRespostas  =
        //  idPesquisaSatRespostas?: number;
        {idPesquisaSatPerguntas: 7,
        descSugestao: this.sugestao,
        criterio: 0,
        dataInclusao: new Date,
        ipUsuario: '',
        recomenda: '',
        usarNovamente: '',
        descPesquisaSatPerguntas: 'Sugestões, reclamações ou comentários sobre o MP Atende',
        numProtocolo: this.registroAtual.atendimento.numProtocolo
        };
        this.pesquisaSatRespostasLista[7] = this.pesquisaSatRespostas;
      }

//      if(this.sugestao  == '' && this.pesquisaSatRespostas.criterio == 0){
 //       semErro   = false;
 //     }

      if(semErro){
  //      this.pesquisaSatRespostasLista.sort((a,b)=> (a.idPesquisaSatRespostas!  < b.idPesquisaSatRespostas!) ? -1 : 1);
        for (let i = 0; i < this.pesquisaSatRespostasLista.length; i++) {
          this.pesquisaSatRespostas = this.pesquisaSatRespostasLista[i];
          this.salvarPesquisaSatRespostas(this.pesquisaSatRespostas).subscribe({
          next: ret => {
          // this.toastr.success('Informação enviada com sucesso.');
            this.pesquisaSatRespostas = ret;
          },
          error: msg => {
            semErro = false;
            this.toastr.error("Erro no envio");
          },

          });
        }
      }
        if(semErro){
          this.toastr.success('Pesquisa enviada com sucesso.');
          this.card = 'none';
        }else{
          this.toastr.error("Erro no envio ou nao respondido");
        }


  /*for (let i = 0; i < this.pesquisaSatRespostasLista.length; i++) {
            this.pesquisaSatRespostas = this.pesquisaSatRespostasLista[i];
                this.toastr.error(this.pesquisaSatRespostas.descPesquisaSatPerguntas);
              //  this.toastr.error(ret => this.pesquisaSatRespostas.criterio);

      }*/
  }


  setandoListaPesquisa(): void {

    this.pesquisaSatRespostas  =
      //  idPesquisaSatRespostas?: number;
      {idPesquisaSatPerguntas: 0,
      descSugestao: '',
      criterio: 0,
      dataInclusao: new Date,
      ipUsuario: '',
      recomenda: '',
      usarNovamente: '',
      descPesquisaSatPerguntas: 'Ao atendimento das minhas necessidades',
      numProtocolo: this.registroAtual.atendimento.numProtocolo
      };
    this.pesquisaSatRespostasLista[0] = this.pesquisaSatRespostas;

    this.pesquisaSatRespostas  =
      //  idPesquisaSatRespostas?: number;
      {idPesquisaSatPerguntas: 1,
      descSugestao: '',
      criterio: 0,
      dataInclusao: new Date,
      ipUsuario: '',
      recomenda: '',
      usarNovamente: '',
      descPesquisaSatPerguntas: 'Desempenho',
      numProtocolo: this.registroAtual.atendimento.numProtocolo
      };
    this.pesquisaSatRespostasLista[1] = this.pesquisaSatRespostas;

    this.pesquisaSatRespostas  =
      //  idPesquisaSatRespostas?: number;
      {idPesquisaSatPerguntas: 2,
      descSugestao: '',
      criterio: 0,
      dataInclusao: new Date,
      ipUsuario: '',
      recomenda: '',
      usarNovamente: '',
      descPesquisaSatPerguntas: 'Facilidade de Uso',
      numProtocolo: this.registroAtual.atendimento.numProtocolo
      };
    this.pesquisaSatRespostasLista[2] = this.pesquisaSatRespostas;

    this.pesquisaSatRespostas  =
      //  idPesquisaSatRespostas?: number;
      {idPesquisaSatPerguntas: 3,
      descSugestao: '',
      criterio: 0,
      dataInclusao: new Date,
      ipUsuario: '',
      recomenda: '',
      usarNovamente: '',
      descPesquisaSatPerguntas: 'À estabilidade (ausência de interrupções frequentes)',
      numProtocolo: this.registroAtual.atendimento.numProtocolo
      };
    this.pesquisaSatRespostasLista[3] = this.pesquisaSatRespostas;

    this.pesquisaSatRespostas  =
      //  idPesquisaSatRespostas?: number;
      {idPesquisaSatPerguntas: 4,
      descSugestao: '',
      criterio: 0,
      dataInclusao: new Date,
      ipUsuario: '',
      recomenda: '',
      usarNovamente: '',
      descPesquisaSatPerguntas: 'À qualidade das informações oferecidas',
      numProtocolo: this.registroAtual.atendimento.numProtocolo
      };
    this.pesquisaSatRespostasLista[4] = this.pesquisaSatRespostas;

    this.pesquisaSatRespostas  =
      //  idPesquisaSatRespostas?: number;
      {idPesquisaSatPerguntas: 5,
      descSugestao: '',
      criterio: 0,
      dataInclusao: new Date,
      ipUsuario: '',
      recomenda: '',
      usarNovamente: '',
      descPesquisaSatPerguntas: 'Você recomendaria o MP Atende',
      numProtocolo: this.registroAtual.atendimento.numProtocolo
      };
    this.pesquisaSatRespostasLista[5] = this.pesquisaSatRespostas;

    this.pesquisaSatRespostas  =
      //  idPesquisaSatRespostas?: number;
      {idPesquisaSatPerguntas: 6,
      descSugestao: '',
      criterio: 0,
      dataInclusao: new Date,
      ipUsuario: '',
      recomenda: '',
      usarNovamente: '',
      descPesquisaSatPerguntas: 'Você usaria novamente o MP Atende',
      numProtocolo: this.registroAtual.atendimento.numProtocolo
      };
    this.pesquisaSatRespostasLista[6] = this.pesquisaSatRespostas;
  }

}

