<label for="ufMoradia">{{ texto }}</label><br>

<!-- Selecionar estado -->
<mat-form-field appearance="outline">
  <mat-label>Estado de moradia</mat-label>
  <mat-select #ufMoradia="ngModel" name="ufMoradia" id="ufMoradia" (selectionChange)="itemUfMoradia(ufMoradia.value)" required=false ngModel>
    <!-- <mat-option value="">Selecione...(obrigatório)</mat-option> -->
    <mat-option value="">Selecione...</mat-option>
    <mat-option *ngFor="let ufMoradia of estadosOptions | keyvalue" value={{ufMoradia.value}}>{{ufMoradia.key}} </mat-option>
  </mat-select>
  <!-- <div class = "msg-validador" *ngIf = "ufMoradia.value == '' && ufMoradia.touched">Obrigatório selecionar</div> -->
</mat-form-field>

&nbsp;&nbsp;&nbsp;

<!-- Selecionar cidade -->
<mat-form-field appearance="outline">
  <mat-label>Cidade de moradia</mat-label>
  <mat-select #cidadeMoradia="ngModel" name="cidadeMoradia" id="cidadeMoradia" (selectionChange)="itemCidadeMoradia(cidadeMoradia.value)" [disabled]="d0" required=false ngModel>
    <!-- <mat-option value="">Selecione...(obrigatório)</mat-option> -->
    <mat-option value="">Selecione...</mat-option>
    <mat-option *ngFor="let cidadeMoradia of cidadesMoradia | async" value={{cidadeMoradia}}>{{cidadeMoradia}} </mat-option>
  </mat-select>
  <!-- <div class = "msg-validador" *ngIf = "cidadeMoradia.value == '' && cidadeMoradia.touched">Obrigatório selecionar</div> -->
</mat-form-field>
