<mat-card class="home mat-elevation-z3">
  <mat-card-content>
    <mat-card-title class="title">{{ texto }}</mat-card-title><br>
    <!-- atendimento repetido -->
    <div *ngIf =  "tipo === 'enviado'" >
      Atendimento já registrado pelo Protocolo nº: <b>{{ d[31] }}</b><br>
      Para consultar um atendimento existente, clique <a href="https://mppr.mp.br/Pagina/MP-Atende-Consulta-protocolo" target="_blank"><b>aqui</b></a>.
<br>
    </div>
    <!-- Resumo -->
    <div *ngIf = "tipo === 'resumo'">
      Número da Solicitação: <b>{{ d[17] }}</b><br>
      Data/Hora da Solicitação: <b>{{ d[3] }}</b><br>
      Cidade onde ocorreu o fato: <b>{{ d[1] }}</b><br>
      Nome: <b>{{ d[27] }}</b><br>
      Nome social: <b>{{ d[15] }}</b><br>
      Sexo: <b>{{ d[28] }}</b><br>
      CPF: <b>{{ d[16] }}</b><br>
      RG: <b>{{ d[18] }}</b><br>
      Data de nascimento: <b>{{ d[5] }}</b><br>
      Nome da mãe: <b>{{ d[14] }}</b><br>
      UF e cidade onde mora: <b>{{ d[26] }}</b><br>
      Telefone: <b>{{ d[22] }}</b><br>
      E-mail: <b>{{ d[8] }}</b><br>
      <div class = "texto">
        Descrição da Solicitação: <b>{{ d[7] }}</b><br>
      </div>
      <!-- Anexos: <b><span *ngFor= "let anexo of atendimento.atendimento.documentoAtendimentoCollection;let i = index">{{ anexo.nomeDocumento }}</span></b><br> -->
      Anexos: <b> {{ d[29] }}</b><br>
      Situação da Solicitação: <b> {{ d[30] }}</b><br>
    </div>

    <!-- Consulta -->
    <div *ngIf = "tipo === 'consulta'">
      Solicitação nº: <b>{{ d[17] }}</b><br>
      Nome: <b>{{ d[27] }}</b><br>
      Situação da Solicitação: <b>{{ d[24] }}</b><br>
      <div class="unidadeAtual"> Unidade atual:&nbsp;<br><br><br><br><br> </div>
      <div class="unidadeAtual"><b>{{ d[25] }}<br>
           Endereço: {{ d[20] }}<br>
                     {{ d[21]}}<br>
                     E-mail: {{ d[9] }}<br>
                     Telefone: {{ d[23] }}</b>
      </div><br>
      Data e Hora da Situação: <b>{{ d[6] }}</b><br>
    </div>

    <br>

    <div class="button-row">
      <button mat-raised-button (click)= goToConsulta() *ngIf = "tipo === 'consulta'" color="primary">Sair</button>
      <button mat-raised-button (click)= goToAtendimento() *ngIf = "tipo === 'resumo' || tipo === 'enviado'" color="primary">Ok</button>
      <button mat-raised-button (click)= servicePDF() *ngIf = "tipo === 'resumo' || tipo === 'consulta'"color="primary">Gerar PDF para imprimir</button>
    </div>
  </mat-card-content>
</mat-card>
