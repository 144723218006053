import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mensagem-recesso',
  templateUrl: './mensagem-recesso.component.html',
  styleUrls: ['./mensagem-recesso.component.scss']
})
export class MensagemRecessoComponent implements OnInit {

  @Output() cienciaRecesso = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  itemCienciaRecesso(ciente : any) {
    this.cienciaRecesso.emit({ ciente: ciente });
  }
}
