import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { PipesModule } from './pipes/pipes.module';
import { DiversosModule } from './diversos/diversos.module';
import { ComboboxModule } from './combobox/combobox.module';

@NgModule({
  declarations: [
  ],
  imports: [CommonModule, FormsModule, PipesModule,DiversosModule,ComboboxModule],
  exports: [CommonModule, FormsModule, PipesModule,DiversosModule,ComboboxModule],
})
export class SharedModule {}
