<mat-toolbar class="header mat-elevation-z4">
    <span>
        <a routerLink="/">
            <img class="logo" src="assets/img/logo-mppr2.png" alt="MPPR">
        </a>
    </span>
    <span class="title-group">
        <a routerLink="{{ routeUrl }}">
            <i class="material-icons">
                {{ icon }}
            </i>
            {{ title }}
        </a>
    </span>
</mat-toolbar>
