<mat-card class="home mat-elevation-z3">
  <!-- <mat-card-title>Mensagem</mat-card-title><br> -->

  <div class = "textoDescricao">

   Bem-vindo ao MP Atende, canal eletrônico de comunicação do Ministério Público do Paraná,
   voltado a receber demandas apresentadas pela população. As demandas recebidas pelo MP Atende
   são analisadas durante os dias e horários de expediente do Ministério Público.
   <br><br>
   <b>ATENÇÃO:</b>
   <br><br>
   <b>Exclusivamente para situações de urgência</b>, a população poderá encaminhar sua demanda à
      equipe do plantão do Ministério Público do Paraná, por meio do seguinte contato:

      <br>
      <br>&emsp;&bull;&ensp;telefone: (41) 99108-8101
    <br><br>
    <b>Importante</b> – O contato de plantão do MPPR é voltado exclusivamente para o atendimento
    às demandas urgentes da população, em situações que são de atribuição do Ministério Público.
    Para informações sobre decisões ou processos judiciais, o contato deve ser realizado diretamente com o
    plantão do Poder Judiciário.


  </div>
</mat-card>
