<!-- Selecionar país -->
<label for="pais" class = "textoDescricao">{{ texto }}</label><br>
<mat-form-field appearance="outline">
  <mat-label>Nacionalidade</mat-label>
  <mat-select name="pais" id="pais" (selectionChange)="selecionando($event.value)" required = false ngModel>
    <!-- <mat-option value="">Selecione...(obrigatório)</mat-option> -->
    <mat-option value="">Selecione...</mat-option>
    <mat-option *ngFor="let pais of paises | async" [value]="pais.idPais">{{pais.nomePais}} </mat-option>
  </mat-select>
</mat-form-field>
