import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mensagem-funcionamento',
  templateUrl: './mensagem-funcionamento.component.html',
  styleUrls: ['./mensagem-funcionamento.component.scss']
})
export class MensagemFuncionamentoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
