import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Atendimento } from 'src/app/shared/modelos/atendimento.model';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-consulta-protocolo',
  templateUrl: './consulta-protocolo.component.html',
  styleUrls: ['./consulta-protocolo.component.scss']
})
export class ConsultaProtocoloComponent implements OnInit, OnChanges {
  public progressVisible: boolean = false;
  public numProtocoloTela: string = "";

  atendimentoResp!: Observable<Atendimento[]>;
  cardResumo = 'none'; // Card 'Resumo do atendimento'
  cardInicial = 'block'; // Card 'Consulta do atendimento'

  atendimento: Atendimento = {
    atendimento: {
      sigilo: 0,
      descricao: '',
      areaAssunto: 0,
      procurouMP: 0,
      possuiNumAtendimento: 0,
      numAtendAnterior: '',
      numProtocolo: '',
      cidadeFato: '',
      dataHoraCadastro: '',
      documentoAtendimentoCollection: [],
    },

    pessoa: {
      nome: '',
      identificado: 0,
      tratadoNomeSocial: '',
      dataNascimento: '',
      nomeMae: '',
    },

    dadosComplementares: {
      nomeSocial: '',
      numCpf: '',
      cidadeMoradia: '',
      ufMoradia: '',
      telefone: '',
      email: '',
      sexo: '',
      numRg: '',
      ufRg: ''
    },

    enderecoUnidade: {
      idUnidade: 0 ,
      nomeLogradouro: '' ,
      complemento: '',
      nomeBairro: '',
      cep: '',
      telefone: '',
      numero: '',
      tipoLogCorreios: ''
      },
  };


  //Teste de submit do form
  onSubmit(formulario: any) {
    //  console.log('Formulário onsubmit :' + formulario);
  this.ngOnInit(); //para poder mostrar o cardInicial quando volta de outro componente
  }

  constructor(private snackBar: MatSnackBar,
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router
) {}


  ngOnInit(): void {
    // console.log('dentro do ngOnInit')
    // this.cardResumo = 'none';
    // this.cardInicial = 'block';
  }

  ngOnChanges(): void {
    console.log("dentro do onchange");
  }
  consultar(): Atendimento {

    this.atendimento.atendimento;
    this.atendimento.atendimento.numProtocolo = this.numProtocoloTela;

    if (this.atendimento.atendimento.numProtocolo != ''){
      this.numProtocoloTela
      this.progressVisible = true;
      this.getConsulta(this.atendimento).subscribe({
        next: ret => {
          this.progressVisible = false;
          this.cardInicial = 'none';
          this.cardResumo = 'block';
          this.toastr.success('Consulta atendimento com sucesso.');
          this.atendimento = ret;
          console.log(this.atendimento)
          this.numProtocoloTela= "";
        },
        error: msg => {
          this.toastr.error("Atendimento não encontrado");
          this.progressVisible = false;
          this.cardInicial = 'block';
          this.cardResumo = 'none';

        },

      });
    } else{
      this.toastr.error("Digite o número do protocolo ");
    }
     return  this.atendimento;
  }


  private URL  = `${environment.back_atendimento}/obterAtendimento?numAtendimento=`;

  getConsulta(atendimento: Atendimento): Observable<Atendimento> {

    this.URL = `${environment.back_atendimento}/obterAtendimento?numAtendimento=` +  this.numProtocoloTela;
    this.progressVisible = true;
    const a = this.http.get<Atendimento>(this.URL).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );

    return a;
  }

  errorHandler(e: any): Observable<any> {
      return e;
    }

  //   goToConsulta() {
  //     this.cardInicial = 'block';
  //     this.cardResumo = 'none';
  //     this.router.navigate(['/consultaProtocolo']);
  // }

  }
