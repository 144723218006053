<mat-card class="home mat-elevation-z3" [style.display]="card" >
  <br>
  <mat-card-title class="title">Pesquisa de Satisfação do Atendimento Digital do MPPR</mat-card-title><br>
  <div id="inicial" >
    <label for="inicial"><b>Deixe sua opinião quanto: (Muito Bom até Péssimo)</b></label>
  </div>
  <br>

<!--  <div>
    <td width="100"> <span [class.rated]="rating > 0" (click)="setRating(1)" class="fa fa-star estrela " ></span></td>
    <td width="100"> <span [class.rated]="rating > 1" (click)="setRating(2)" class="fa fa-star estrela"></span></td>
    <td width="100"> <span [class.rated]="rating > 2" (click)="setRating(3)" class="fa fa-star estrela"></span></td>
    <td width="100"> <span [class.rated]="rating > 3" (click)="setRating(4)" class="fa fa-star estrela"></span></td>
    <td width="100"> <span [class.rated]="rating > 4" (click)="setRating(5)" class="fa fa-star estrela"></span></td>

  </div>
<br><br><br> -->
<mat-card>
  <br>
        <td width="350"><label></label></td>
        <td width="100"> <span >Muito Bom<mat-icon></mat-icon></span></td>
        <td width="100"> <span >Bom<mat-icon></mat-icon></span></td>
        <td width="100"> <span >Regular<mat-icon></mat-icon></span></td>
        <td width="100"> <span >Ruim<mat-icon></mat-icon></span></td>
        <td width="100"> <span >Péssimo<mat-icon></mat-icon></span></td>

  </mat-card>

<mat-card>
    <td width="350"><label>Ao atendimento das minhas necessidades</label></td>
    <td width="100"> <span (click)="setRating(5)"><mat-icon [class.ratedBlack]="rating == 5"  [class.cara5]="rating != 5"  >sentiment_very_satisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRating(4)"><mat-icon [class.ratedBlack]="rating == 4"  [class.cara4]="rating != 4"  >sentiment_satisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRating(3)"><mat-icon [class.ratedBlack]="rating == 3"  [class.cara3]="rating != 3"  >sentiment_dissatisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRating(2)"><mat-icon [class.ratedBlack]="rating == 2"  [class.cara2]="rating != 2"  >sentiment_very_dissatisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRating(1)"><mat-icon [class.ratedBlack]="rating == 1"  [class.cara1]="rating != 1"  >mood_bad_very</mat-icon></span></td>
<br>
</mat-card>
<mat-card>
    <td width="350"><label>Desempenho</label></td>
    <td width="100"> <span (click)="setRatingDesempenho(5)"><mat-icon  [class.ratedBlack]="ratingDesempenho == 5" [class.cara5]="ratingDesempenho != 5" >sentiment_very_satisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRatingDesempenho(4)" ><mat-icon [class.ratedBlack]="ratingDesempenho == 4" [class.cara4]="ratingDesempenho != 4"  >sentiment_satisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRatingDesempenho(3)" ><mat-icon [class.ratedBlack]="ratingDesempenho == 3" [class.cara3]="ratingDesempenho != 3"  >sentiment_dissatisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRatingDesempenho(2)" ><mat-icon [class.ratedBlack]="ratingDesempenho == 2" [class.cara2]="ratingDesempenho != 2"  >sentiment_very_dissatisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRatingDesempenho(1)" ><mat-icon [class.ratedBlack]="ratingDesempenho== 1"  [class.cara1]="ratingDesempenho != 1"  >mood_bad_very</mat-icon></span></td>
</mat-card>
<mat-card>
<br>
    <td width="350"><label>Facilidade de Uso</label></td>
    <td width="100"> <span (click)="setRatingFacilidade(5)"><mat-icon  [class.ratedBlack]="ratingFacilidade == 5" [class.cara5]="ratingFacilidade != 5" >sentiment_very_satisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRatingFacilidade(4)" ><mat-icon [class.ratedBlack]="ratingFacilidade == 4" [class.cara4]="ratingFacilidade != 4"  >sentiment_satisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRatingFacilidade(3)" ><mat-icon [class.ratedBlack]="ratingFacilidade == 3" [class.cara3]="ratingFacilidade != 3"  >sentiment_dissatisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRatingFacilidade(2)" ><mat-icon [class.ratedBlack]="ratingFacilidade == 2" [class.cara2]="ratingFacilidade != 2"  >sentiment_very_dissatisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRatingFacilidade(1)" ><mat-icon [class.ratedBlack]="ratingFacilidade== 1"  [class.cara1]="ratingFacilidade != 1"  >mood_bad_very</mat-icon></span></td>
</mat-card>
<mat-card>
<br>
    <td width="350"><label>À estabilidade (ausência de interrupções frequentes)</label></td>
    <td width="100"> <span (click)="setRatingEstabilidade(5)"><mat-icon  [class.ratedBlack]="ratingEstabilidade == 5" [class.cara5]="ratingEstabilidade != 5" >sentiment_very_satisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRatingEstabilidade(4)" ><mat-icon [class.ratedBlack]="ratingEstabilidade == 4" [class.cara4]="ratingEstabilidade != 4"  >sentiment_satisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRatingEstabilidade(3)" ><mat-icon [class.ratedBlack]="ratingEstabilidade == 3" [class.cara3]="ratingEstabilidade != 3"  >sentiment_dissatisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRatingEstabilidade(2)" ><mat-icon [class.ratedBlack]="ratingEstabilidade == 2" [class.cara2]="ratingEstabilidade != 2"  >sentiment_very_dissatisfied</mat-icon></span></td>
    <td width="100"> <span (click)="setRatingEstabilidade(1)" ><mat-icon [class.ratedBlack]="ratingEstabilidade== 1"  [class.cara1]="ratingEstabilidade != 1"  >mood_bad_very</mat-icon></span></td>
</mat-card>
<mat-card>
<br>
      <td width="350"><label>À qualidade das informações oferecidas</label></td>
      <td width="100"> <span (click)="setRatingQualidade(5)"><mat-icon  [class.ratedBlack]="ratingQualidade == 5" [class.cara5]="ratingQualidade != 5" >sentiment_very_satisfied </mat-icon></span></td>
      <td width="100"> <span (click)="setRatingQualidade(4)" ><mat-icon [class.ratedBlack]="ratingQualidade == 4" [class.cara4]="ratingQualidade != 4"  >sentiment_satisfied</mat-icon></span></td>
      <td width="100"> <span (click)="setRatingQualidade(3)" ><mat-icon [class.ratedBlack]="ratingQualidade == 3" [class.cara3]="ratingQualidade != 3"  >sentiment_dissatisfied</mat-icon></span></td>
      <td width="100"> <span (click)="setRatingQualidade(2)" ><mat-icon [class.ratedBlack]="ratingQualidade == 2" [class.cara2]="ratingQualidade != 2"  >sentiment_very_dissatisfied</mat-icon></span></td>
      <td width="100"> <span (click)="setRatingQualidade(1)" ><mat-icon [class.ratedBlack]="ratingQualidade == 1" [class.cara1]="ratingQualidade != 1"  >mood_bad_very</mat-icon></span></td>

</mat-card>
<!--
  <mat-card>
    <mat-card>
      <mat-radio-group class="example-radio-group" (change)="radioButtonGroupIdentificado($event)"
            name="riNecessidade" id="riNecessidade" >
            <td width="350"><label>Ao atendimento das minhas necessidades</label></td>
            <td width="120"><mat-radio-button class="example-radio-button" value="1">Muito Bom&nbsp;</mat-radio-button></td>
            <td width="100"><mat-radio-button class="example-radio-button" value="2">Bom&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button></td>
            <td width="100"><mat-radio-button class="example-radio-button" value="3">Ruim&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button></td>
            <td width="120"><mat-radio-button class="example-radio-button" value="4">Muito Ruim</mat-radio-button></td><br>
      </mat-radio-group>
    </mat-card>
    <mat-card>
      <mat-radio-group class="example-radio-group" (change)="radioButtonGroupIdentificado($event)"
            name="riDesempenho" id="riDesempenho" >
            <td width="350"><label>Desempenho</label></td>
            <td width="120"><mat-radio-button class="example-radio-button" value="1">Muito Bom&nbsp;</mat-radio-button></td>
            <td width="100"><mat-radio-button class="example-radio-button" value="2">Bom&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button></td>
            <td width="100"><mat-radio-button class="example-radio-button" value="3">Ruim&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button></td>
            <td width="120"><mat-radio-button class="example-radio-button" value="4">Muito Ruim</mat-radio-button></td><br>
      </mat-radio-group>
    </mat-card>
    <mat-card>
      <mat-radio-group class="example-radio-group" (change)="radioButtonGroupIdentificado($event)"
            name="riFacilidade" id="riFacilidade" >
            <td width="350"><label>À facilidade de uso</label></td>
            <td width="120"><mat-radio-button class="example-radio-button" value="1">Muito Bom&nbsp;</mat-radio-button></td>
            <td width="100"><mat-radio-button class="example-radio-button" value="2">Bom&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button></td>
            <td width="100"><mat-radio-button class="example-radio-button" value="3">Ruim&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button></td>
            <td width="120"><mat-radio-button class="example-radio-button" value="4">Muito Ruim</mat-radio-button></td><br>
      </mat-radio-group>
    </mat-card>

      <mat-card>
        <mat-radio-group class="example-radio-group" (change)="radioButtonGroupIdentificado($event)"
              name="riEstabilidade" id="riEstabilidade" >
              <td width="350"><label>À estabilidade (ausência de interrupções frequentes)</label></td>
              <td width="120"><mat-radio-button class="example-radio-button" value="1">Muito Bom&nbsp;</mat-radio-button></td>
              <td width="100"><mat-radio-button class="example-radio-button" value="2">Bom&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button></td>
              <td width="100"><mat-radio-button class="example-radio-button" value="3">Ruim&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button></td>
              <td width="120"><mat-radio-button class="example-radio-button" value="4">Muito Ruim</mat-radio-button></td><br>
          </mat-radio-group>
      </mat-card>
      <mat-card>
        <mat-radio-group class="example-radio-group" (change)="radioButtonGroupIdentificado($event)"
              name="riQualidade" id="riQualidade" >
                <td width="350"><label>À qualidade das informações oferecidas</label></td>
                <td width="120"><mat-radio-button class="example-radio-button" value="1">Muito Bom&nbsp;</mat-radio-button></td>
                <td width="100"><mat-radio-button class="example-radio-button" value="2">Bom&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button></td>
                <td width="100"><mat-radio-button class="example-radio-button" value="3">Ruim&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button></td>
                <td width="120"><mat-radio-button class="example-radio-button" value="4">Muito Ruim</mat-radio-button></td><br>
          </mat-radio-group>
      </mat-card>
  </mat-card>
   -->


  <mat-card>
    <mat-radio-group class="example-radio-group" (change)="radioButtonGroupIdentificado($event)"
          name="riRecomenda" id="riRecomenda" >Você recomendaria o MP Atende&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <mat-radio-button class="example-radio-button" value="S">Sim&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button>
          <mat-radio-button class="example-radio-button" value="N">Não&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button><br>
    </mat-radio-group>
  </mat-card>
  <mat-card>
    <mat-radio-group class="example-radio-group" (change)="radioButtonGroupIdentificado($event)"
          name="riUsaria" id="riUsaria" >Você usaria novamente o MP Atende &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <mat-radio-button class="example-radio-button" value="S">Sim&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button>
          <mat-radio-button class="example-radio-button" value="N">Não&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button><br>
    </mat-radio-group>
  </mat-card>


  <br>
  <div id="sugestao" >
    <label for="sugestao"><b>Sugestões, reclamações ou comentários sobre o MP Atende:</b></label><br>
    <textarea maxlength="2000" id="sugestao" name="sugestao" [(ngModel)]=sugestao></textarea>
    <mat-hint align="end"><strong>Máximo de caracteres : {{ sugestao.length }}/2000</strong></mat-hint>
  </div>
  <br>
  <button mat-raised-button color="primary" (click)="enviar()">Enviar</button>

</mat-card>
<br>
