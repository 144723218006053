<mat-card class="home mat-elevation-z3" id="anexar">
  <mat-card-content>
    <mat-card-title class="title">Documentos, Imagens ou Vídeos</mat-card-title><br>
    <label for="arquivoEscolher">Possui documentos, imagens ou vídeos que ajudem a esclarecer a situação?<br>Compartilhe aqui.</label>
    <br><br>
    <div class="custom-file">
      <input type="file" ng2FileSelect [uploader]="uploader" name="arquivoEscolher" class="custom-file-input "
        id="customFile"/>
      <label class="custom-file-label" data-browse="Escolher" for="customFile">Escolha o arquivo</label>
    </div>
    <i>
      <br>Somente arquivos doc, odt, pdf abaixo de 10MB, ou webm abaixo de 300MB.<br>
    </i>

    <div class="form-group col-sm-8 arquivos" >
      <!-- <label class="col-md-12 col-form-label" for="arquivoArrastar">Documentos selecionados: </label> -->
      <br>
      Documentos selecionados:
      <table class="table ">
        <thead>
          <tr>
            <th width="35%">Nome</th>
            <th width="13%">Excluir</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let documento of registroAtual.atendimento.documentoAtendimentoCollection">
            <tr>
              <td>
                <strong>{{ documento.nomeDocumento }}</strong>
              </td>

              <button type="button" class="btn btn-danger btn-s" title="Excluir documento"
                (click)="removeDocumento(documento)">
                <!-- <i class="fas fa-trash d-block"></i> -->
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <!-- <div class="spinnerSection" *ngIf ="progressVisible == 'true' ">
        <label>Aguarde, carregando...</label>
        <mat-spinner></mat-spinner>
      </div> -->
      <h4 class="spinnerSection" *ngIf ="progressVisible == 'true'">Aguarde, carregando...</h4>
      <div class="spinnerSection" *ngIf ="progressVisible == 'true'">
          <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
        <!-- <mat-progress-bar mode="buffer" color="warn"></mat-progress-bar> -->
      </div>

    </div>
  </mat-card-content>

</mat-card>
