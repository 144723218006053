import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cidade } from '../../modelos/cidade.model';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class SelecionarCidadeService {
  private URL = `${environment.back_atendimento}/listarCidadesComarca?uf=PR`;

  constructor(private http: HttpClient) {}

  getCidades(): Observable<Cidade[]> {
    return this.http.get<Cidade[]>(this.URL);
  }
}
