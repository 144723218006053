import { ConsultaProtocoloComponent } from './demandas/atendimento/consulta-protocolo/consulta-protocolo.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AtendimentoCadastrarComponent } from './demandas/atendimento/cadastrar/atendimento-cadastrar.component';
import { PerguntasRespostasComponent } from './demandas/atendimento/perguntas-respostas/perguntas-respostas.component';
import { HomeComponent } from "./views/home/home.component";

const routes: Routes = [
  { path: '', component: AtendimentoCadastrarComponent },
  // { path: '', component: HomeComponent },
  { path: 'atendimento', component: AtendimentoCadastrarComponent },
  { path: 'perguntas', component: PerguntasRespostasComponent },
  { path: 'consultaProtocolo', component: ConsultaProtocoloComponent },
  // roteamento default
  { path: '', pathMatch: 'full', redirectTo: 'dataForm' }
];

// export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes);
@NgModule({
  imports: [RouterModule.forRoot(routes),
            // Permite o 'refresh' da página de destino antes de carregar
            RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
