<!-- Selecionar sexo -->
<label for="sexo"  class="required">{{ texto+':' }}</label><br>
<mat-form-field appearance="outline">
  <mat-label>{{ texto }}</mat-label>
  <mat-select #sexo="ngModel" name="sexo" id="sexo" (selectionChange)="itemSexoSelecionado(sexo.value)" required=false ngModel>
    <mat-option value="">Selecione...</mat-option>
    <mat-option *ngFor="let sexo of sexoOptions | keyvalue" value={{sexo.value}}>{{sexo.key}} </mat-option>
  </mat-select>
  <div class = "msg-validador" *ngIf = "sexo.value == '' && sexo.touched">Obrigatório selecionar</div>
</mat-form-field>
