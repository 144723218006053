<mat-toolbar class="footer">
    <span>
        <!-- Desenvolvido com
        <i class="material-icons v-middle" appRed>
            favorite
        </i>
        por <strong>Cod<span class="red">3</span>r</strong> -->
        <strong>2022 - Ministério Público do Paraná</strong>
    </span>
</mat-toolbar>
