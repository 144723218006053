<!-- Selecionar estado -->
<label for="estado">{{ texto }}</label><br>
<!-- <div class = "msg-validador" *ngIf = "estado.value == '' && estado.touched">Obrigatório informar</div> -->
<mat-form-field appearance="outline">
  <mat-label>Estado</mat-label>
  <mat-select #estado="ngModel" name="estado" id="estado" (selectionChange)="itemEstado(estado.value)" required=false ngModel>
    <mat-option value="">Selecione...(obrigatório)</mat-option>
    <mat-option *ngFor="let estado of estadosOptions | keyvalue" value={{estado.value}}>{{estado.key}} </mat-option>
  </mat-select>
</mat-form-field>
