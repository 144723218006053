import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Atendimento } from '../../../shared/modelos/atendimento.model';
import { Observable, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class AtendimentoCadastrarSalvarService {
   
  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private toastr: ToastrService
  ) {}

  showMessage(msg: string, isError: boolean = false): void {
    this.snackBar.open(msg, 'X', {
      duration: 6000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
  private url1 = `${environment.back_atendimento}/listarAtendimentos?`;
  private url2 = `${environment.back_atendimento}/salvarAtendimento`;

  getAtendimentoComplementar(): Observable<Atendimento[]> {
    return this.http.get<Atendimento[]>(this.url1);
  }

  salvarAtendimento(atendimento: Atendimento): Observable<Atendimento> {
    
    const a = this.http.post<Atendimento>(this.url2, atendimento).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
    
    return a;
  }

  // salvarAtendimento() {
  //   console.log('salvei');
  // }

  errorHandler(e: any): Observable<any> {
  //  this.toastr.error('Ocorreu um erro!');
    // this.showMessage('Ocorreu um erro!', true);
    return e;
  }
}
