import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { EstadosOptions } from '../../enum/estados.enum';

@Component({
  selector: 'app-selecionar-estado',
  templateUrl: './selecionar-estado.component.html',
  styleUrls: ['./selecionar-estado.component.scss'],
})
export class SelecionarEstadoComponent implements OnInit {

  @Input() texto: string | undefined;
  @Output() estadoSelecionado = new EventEmitter();

  estadosOptions = EstadosOptions;

  constructor() {}

  ngOnInit(): void {}

  itemEstado(estado: any) {
    this.estadoSelecionado.emit({ estado: estado });
  }
}
