import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { SelecionarCidadeService } from './selecionar-cidade.service';
import { Cidade } from '../../modelos/cidade.model';
import { Observable } from 'rxjs';
import { MatSelectModule } from '@angular/material/select';
@Component({
  selector: 'app-selecionarcidade',
  templateUrl: './selecionar-cidade.component.html',
  styleUrls: ['./selecionar-cidade.component.scss'],
  providers: [SelecionarCidadeService],
})
export class SelecionarCidadeComponent implements OnInit {
  cidades!: Observable<Cidade[]>;

  @Input() texto: string | undefined;
  // @Input() texto2: string | undefined;
  @Output() selecionado = new EventEmitter();

  constructor(private selecionarCidadeService: SelecionarCidadeService) {}

  ngOnInit(): void {
    this.cidades = this.selecionarCidadeService.getCidades();
    // console.log('Inicializou cidade');
  }

  selecionando(x: any) {
    this.selecionado.emit({ cidade: x });
  }
}
