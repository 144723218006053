<!-- Selecionar cidade -->
<label for="cidade" class = "textoDescricao, required">{{ texto }}</label><br><br>
<mat-form-field appearance="outline">
  <mat-label>Cidade</mat-label>
  <mat-select #cidade="ngModel" name="cidade" id="cidade" (selectionChange)="selecionando(cidade.value)" required = false ngModel>
    <mat-option value="">Selecione...</mat-option>
    <mat-option *ngFor="let cidade of cidades | async" value={{cidade}}>{{cidade}} </mat-option>
  </mat-select>
  <div class = "msg-validador" *ngIf = "cidade.value == '' && cidade.touched">Obrigatório informar</div>
</mat-form-field>

