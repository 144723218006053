<mat-sidenav-container class="sidenav-container">
  <mat-sidenav class="sidenav" mode="side" opened fixedInViewport="true" fixedTopGap="64">
    <mat-nav-list class="nav-list">
      <a mat-list-item routerLink="/atendimento">
        <i class="material-icons">
          person
        </i>
        Atendimento
      </a>
      <a mat-list-item routerLink="/consultaProtocolo">
        <i class="material-icons">
          feed
        </i>
        Consulta protocolo
      </a>
      <a mat-list-item routerLink="/perguntas">
        <i class="material-icons">
          help
        </i>
        Perguntas & Respostas
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
