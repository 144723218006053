import { PesquisaSatisfacaoComponent } from './shared/diversos/pesquisa-satisfacao/pesquisa-satisfacao.component';
import { TituloAplicacaoComponent } from './shared/diversos/titulo-aplicacao/titulo-aplicacao.component';
import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppComponent } from './app.component';
import { AtendimentoCadastrarComponent } from './demandas/atendimento/cadastrar/atendimento-cadastrar.component';
import { FooterComponent } from './demandas/template/footer/footer.component';
import { HeaderComponent } from './demandas/template/header/header.component';
import { NavComponent } from './demandas/template/nav/nav.component';
import { HomeComponent } from './views/home/home.component';

// import { AppRoutingModule, routing } from './app-routing.module';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SelecionarCidadeComponent } from './shared/combobox/selecionar-cidade/selecionar-cidade.component';
import { SelecionarEstadoCidadeComponent } from './shared/combobox/selecionar-estado-cidade/selecionar-estado-cidade.component';
import { SelecionarSexoComponent } from './shared/combobox/selecionar-sexo/selecionar-sexo.component';
import { SharedModule } from './shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';
// import { MAT_DATE_LOCALE } from '@angular/material/core';
// import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { AnexarArquivoComponent } from './shared/diversos/anexar-arquivo/anexar-arquivo.component'
import { DadosAtendimentoComponent } from './shared/diversos/dados-atendimento/dados-atendimento.component';

import { ToastrModule } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar'

//Data
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateModule } from '@angular/material-moment-adapter';

//Localização
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { PerguntasRespostasComponent } from './demandas/atendimento/perguntas-respostas/perguntas-respostas.component';

registerLocaleData(localePt, 'pt');
import { MatExpansionModule } from '@angular/material/expansion';
import { ConsultaProtocoloComponent } from './demandas/atendimento/consulta-protocolo/consulta-protocolo.component';
import { SelecionarEstadoComponent } from './shared/combobox/selecionar-estado/selecionar-estado.component';

import { MaskPipe } from 'ngx-mask';
import { MensagemRecessoComponent } from './demandas/atendimento/mensagem-recesso/mensagem-recesso.component';
import { LinksComponent } from './demandas/atendimento/links/links.component';
import { MensagemIndisponibilidadeComponent } from './demandas/atendimento/mensagem-indisponibilidade/mensagem-indisponibilidade.component';
import { MensagemFuncionamentoComponent } from './demandas/atendimento/mensagem-funcionamento/mensagem-funcionamento.component';
import { SelecionarPaisComponent } from './shared/combobox/selecionar-pais/selecionar-pais.component';
import { MensagemAtencaoComponent } from './demandas/atendimento/mensagem-atencao/mensagem-atencao.component';
import { DenunciaEleitoralComponent } from './demandas/atendimento/denuncia-eleitoral/denuncia-eleitoral.component';

@NgModule({
  declarations: [
    AnexarArquivoComponent,
    AppComponent,
    AtendimentoCadastrarComponent,
    FooterComponent,
    HeaderComponent,
    NavComponent,
    HomeComponent,
    PerguntasRespostasComponent,
    DadosAtendimentoComponent,
    SelecionarCidadeComponent,
    SelecionarEstadoCidadeComponent,
    SelecionarSexoComponent,
    ConsultaProtocoloComponent,
    TituloAplicacaoComponent,
    SelecionarEstadoComponent,
    PesquisaSatisfacaoComponent,
    MensagemRecessoComponent,
    LinksComponent,
    MensagemIndisponibilidadeComponent,
    MensagemFuncionamentoComponent,
    SelecionarPaisComponent,
    MensagemAtencaoComponent,
    DenunciaEleitoralComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MomentDateModule,
    SharedModule,
    NgxMaskModule.forRoot({
      dropSpecialCharacters: true,
    }),
    // MatDateFnsModule,
    ToastrModule.forRoot(),
    MatExpansionModule,
    MatCheckboxModule,
    // routing,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    {
       provide: MaskPipe,
       useClass: MaskPipe
    },

    // {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
