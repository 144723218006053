export enum SexoEnum {
  MASCULINO = 'Masculino',
  FEMININO = 'Feminino',
  OUTRO = 'Outro',
}

export const sexoOptions = new Map([
  [SexoEnum.MASCULINO, 1],
  [SexoEnum.FEMININO, 2],
  [SexoEnum.OUTRO, 3],
]);
