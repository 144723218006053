import { HeaderService } from './../../demandas/template/header/header.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private headerService: HeaderService) {
    headerService.headerData = {
      // title: 'Início',
      // icon: 'home',
      title: 'HUB de funcionalidades do MPAtende',
      icon: 'device_hub',
      routeUrl: ''
    }
  }

  ngOnInit(): void {
  }

}
