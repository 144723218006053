import { Injectable } from '@angular/core';
// import '../assets/fonts/CourierPrime-Regular-normal.js';
import { jsPDF } from 'jspdf';

@Injectable({
  providedIn: 'root'
})

// -----------
// Referências
// -----------

// https://rawgit.com/MrRio/jsPDF/master/docs/jsPDF.html
// https://rawgit.com/MrRio/jsPDF/master/docs/

export class DadosAtendimentoService {

  constructor() { }

  gerarPDF(d: string[], tipo : string | undefined)  {

    // -----------------------
    // Configurações da página
    // -----------------------

    let doc = new jsPDF({
      format: 'a4',
      orientation: 'portrait',
      unit: 'mm',
      floatPrecision: 16,
    });

    // ------------------------------------------------
    // Variáveis e funções de construção compartilhadas
    // ------------------------------------------------

    var x: number = 15;           // Espaço x
    var m: number = x + 2;        // Margem esquerda
    var y: number = -16;          // Espaço y
    var apr: number = 9;          // Altura padrão do retângulo
    var drt: number = 6;          // Distância relativa do texto
    var dtl: number = 2;          // Distância entre o texto à direita e o separador
    var lr: number = 210 - (2*x); // Largura padrão do retângulo
    var fs: number = 13;          // Tamanho da fonte
    var rx: number = 2;           // Radius along x axis
    var ry: number = 2;           // Radius along y axis

    function carregaFontePadrao() {
      doc.setFont("Helvetica");
      // Fontes nativas :
      // Courier,Courier-Bold,Courier-BoldOblique,Courier-Oblique,Helvetica,
      // Helvetica-Bold,Helvetica-BoldOblique,Helvetica-Oblique,Symbol,
      // Times-Roman,Times-Bold,Time-Italic,Time-BoldItalic
      doc.setFontSize(fs);
    }

    carregaFontePadrao();

    // Cria cabeçalho
    function criaCabecalho(tipo : string) {
      var a : string = tipo;
      doc.addImage(d[10],'PNG',17,9,24.49,29.53,'brasao-pr','MEDIUM',0);
      doc.setFontSize(16);
      doc.text('Ministério Público do Estado do Paraná',65,23);
      if (tipo == 'resumo') {
        doc.text('MP Atende - Resumo da Solicitação',67,31);
      }
      else if (tipo == 'consulta') {
        doc.text('MP Atende - Acompanhamento da Solicitação',59,31);
      }
      doc.setFontSize(fs);
    }

    // Cria caixa e texto
    // l : rótulo da caixa
    // o: texto da caixa
    // n1 : coordenada y relativa do retângulo
    // n2 : coordenada x da linha separadora
    function criaCaixaTexto(l:string,o:string,n1:number,n2:number){
      // doc.rect(x,y+n1,lr,apr,'S');
      doc.roundedRect(x,y+n1,lr,apr,rx,ry,'S');
      doc.text(l,m,y+drt+n1);
      doc.line(m+n2,y+n1,m+n2,y+apr+n1,'S');
      doc.text(o,m+dtl+n2,y+drt+n1);
    }

    // Obtém informações
    function getInfoDoc() {
      console.log("==> Show all font in jsPDF'" + doc.getFontList());
    }

    getInfoDoc();

    // -----------------------------------------------------------------
    // Itens comuns para 'Consultar protocolo' e 'Resumo do atendimento'
    // -----------------------------------------------------------------

    // Nº do protocolo
    //criaCaixaTexto('Solicitação nº :',d[17] + '        |    Data/hora da Solic.: '+ d[3],65,29)
    //criaCaixaTexto('Data Cadastro: ',d[3],146,37)
    // criaCaixaTexto('Protocolo Enviado  nº :',d[31],65,29)

    // Data da consulta
    // doc.line(m+75,y+65,m+75,y+65+ar,'S');
    // doc.text('Data da consulta: ',m+77,y+71.4);
    // doc.line(m+116,y+65,m+116,y+65+ar,'S');
    // doc.text(dataConsulta,m+118,y+71.4);

    // ---------------------
    // Consultar o protocolo
    // ---------------------
    if (tipo == 'consulta') {
      criaCaixaTexto('Solicitação nº :',d[17] + '        |    Data/hora da Solic.: '+ d[3],65,29)
      criaCabecalho(tipo);
      doc.setFontSize(fs);

      // Atendido
      criaCaixaTexto('Nome : ',d[27],78,25)

      // Tipo do trâmite

      criaCaixaTexto('Situação Solic. :',d[24],91,37)

      // Unidade atual
      doc.roundedRect(x,y+104,x+18,apr,rx,ry,'S');
      doc.text('Unidade atual',m,y+drt+104);
      doc.roundedRect(x,y+113,lr,apr+20,rx,ry,'S');
      doc.setFontSize(10);
      doc.text(d[25],m,y+119);
      doc.text('Endereço: ' + d[20],m,y+drt+119);
      doc.text(d[21],m+17,y+129);
      doc.text('Email: ' + d[9],m,y+134);
      doc.text('Telefone: ' + d[23],m,y+139);
      doc.setFontSize(fs);

      // Data e Hora do trâmite
      criaCaixaTexto('Data da Situação: ',d[6],146,37)

    }

    // ---------------------
    // Resumo do atendimento
    // ---------------------

    if (tipo == 'resumo') {
      criaCaixaTexto('Solicitação nº :',d[17],65,29)
      criaCabecalho(tipo);
      doc.setFontSize(fs);

      // Cidade onde ocorreu o fato
      criaCaixaTexto('Cidade do fato : ',d[1],78,34)

      // Atendido
      criaCaixaTexto('Nome : ',d[27],91,23)

      // Nome social
      criaCaixaTexto('Nome Social : ',d[15],104,31)

      // Sexo
      criaCaixaTexto('Sexo : ',d[28],117,16)

      // CPF
      doc.line(m+50,y+117,m+50,y+apr+117,'S');
      doc.text('CPF : ',m+52,y+drt+117);
      doc.line(m+65,y+117,m+65,y+apr+117,'S');
      doc.text(d[16],m+dtl+65,y+drt+117);

      // RG
      doc.line(m+101,y+117,m+101,y+apr+117,'S');
      doc.text('RG : ',m+103,y+drt+117);
      doc.line(m+114,y+117,m+114,y+apr+117,'S');
      doc.text(d[18],m+dtl+116,y+drt+117);

      // Data de nascimento
      criaCaixaTexto('Data de nascimento : ',d[5],130,46)

      // Nome da mãe
      criaCaixaTexto('Nome da mãe : ',d[14],143,34)

      // UF e cidade onde mora
      criaCaixaTexto('UF e cidade onde mora : ',d[26],156,53)

      // Telefone
      criaCaixaTexto('Telefone : ',d[22],169,23)

      // E-mail
      criaCaixaTexto('E-mail : ',d[8],182,18)

      // Descrição da demanda - Abordagem antiga
      // Mostra título
      // Desenha retângulo e insere o texto
      // doc.rect(x,y+apr+195,lr,apr+53,'S');
      // doc.setFontSize(8);
      // doc.text(d[7],m,y+208,{ maxWidth:176 });
      // doc.setFontSize(fs);

      // Descrição da demanda - Abordagem nova
      // Ajusta a altura do retângulo conforme tamanho do texto
      var al = 4;
      var apr2: number = 0;

      var ajuste1 = y+195;

      // Até 15*100 = 1500 caracteres não altera a tela

      // Remover o caracter de quebra de linha
      // Está afetando o cálculo de linsa necessárias
      d[7] = d[7].replace(/[\r\n]/gm,' ');

      if (d[7].length > 1500) {
        doc.addPage();
        criaCabecalho(tipo);
        ajuste1 = y+65;
      }

      // Calcula o número de linhas (limite : 100 caracteres por linha)
      (d[7].length <= 100)?apr2 = al:apr2 = Math.ceil((d[7].length)/100)*al;
      // (d[7].length <= 100)?apr2 = al:apr2 = (d[7].length)/100*al;
      doc.roundedRect(x,ajuste1,x+37,apr,rx,ry,'S');
      doc.text('Descrição da Solicitação',m,ajuste1+drt);
      doc.roundedRect(x,ajuste1+apr,lr,apr2,rx,ry,'S');
      // doc.text(d[7],m+3,y+208,{ maxWidth:170 });
      doc.setFont("Courier");
      doc.setFontSize(8);
      doc.text(d[7],m+3,ajuste1+12.2,{ maxWidth:170,align:'justify' });

      carregaFontePadrao();

      var ajuste2 = ajuste1+apr+apr2+20;

      // Anexos
      // criaCaixaTexto('Anexos :',d[29],270,20)
      criaCaixaTexto('Anexos :',d[29],ajuste2,20);

      // Situação
      // criaCaixaTexto('Situação :',d[30],283,22)
      criaCaixaTexto('Situação da Solic.:',d[30],ajuste2+13,40);
      criaCaixaTexto('Situação da Solic.:',d[30],ajuste2+13,40);

    }

    // Geração do pdf
    // doc.output("dataurlnewwindow");
    doc.save(tipo+"_"+d[17] + '.pdf');
  }
}
