import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mensagem-indisponibilidade',
  templateUrl: './mensagem-indisponibilidade.component.html',
  styleUrls: ['./mensagem-indisponibilidade.component.scss']
})
export class MensagemIndisponibilidadeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
