import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { SelecionarPaisService } from './selecionar-pais.service';
import { Pais } from '../../modelos/pais.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-selecionar-pais',
  templateUrl: './selecionar-pais.component.html',
  styleUrls: ['./selecionar-pais.component.scss']
})
export class SelecionarPaisComponent implements OnInit {

  paises!: Observable<Pais[]>;

  @Input() texto: string | undefined;
  @Output() paisSelecionado : EventEmitter<number> = new EventEmitter<number>();

  constructor( private selecionarPaisService: SelecionarPaisService ) { }

  ngOnInit(): void {
    this.paises = this.selecionarPaisService.getPaises();
  }

  selecionando(x: number) {
    this.paisSelecionado.emit(x);
  }

}
