import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DataHoraService {
  private dataCache! : any;

  // guarda o id do intervalo
  private idIntervalo! : any;

  private intervalo = 1000;

  private idIntervaloReinicar! : any;

  // reatualiza o intervalo a cada 5 min
  private intervaloReiniciar = 3600000;

  readonly dataHoraAtual$ = this.http.get<Date>(`${environment.back_data_hora}/data-hora`).pipe(
    map(data => {
      this.pararCache();
      this.dataCache = new Date(data);
      this.iniciarCache();
      return data;
    }),
  );

  constructor(private http: HttpClient) {}

  iniciarCache() {
    this.idIntervalo = setInterval(() => {
      this.dataCache.setSeconds(this.dataCache.getSeconds() + 1);
    }, this.intervalo);

    this.idIntervaloReinicar = setInterval(() => {
      this.getHoraAtualAsync(dados => {});
    }, this.intervaloReiniciar);
  }
  pararCache() {
    if (this.idIntervalo != null) {
      clearInterval(this.idIntervalo);
    }
    if (this.idIntervaloReinicar != null) {
      clearInterval(this.idIntervaloReinicar);
    }
  }

  getHoraAtualAsync(callback: (_: Date) => void) {
    this.http.get<Date>(`${environment.back_data_hora}/data-hora`).subscribe(data => {
      this.pararCache();
      this.dataCache = new Date(data);
      this.iniciarCache();
      callback(data);
    });
  }

  getHoraAtualCache(): Date {
    return new Date(this.dataCache);
  }

  // Função a ser modificada, colocado apenas um retorno aqui
  obtemDataHora(): Date {
    return new Date();
  }
}
