import { Atendimento } from './../../modelos/atendimento.model';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DocumentoAtendimento } from '../../modelos/documento-atendimento.model';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { FileItem, FileLikeObject, FileUploader } from 'ng2-file-upload';
import { GoogleDriveService } from '../../services/google-drive.service';
import { Router } from '@angular/router';
// import { SpinnerOverlayService } from '../../spinner/spinner-overlay.service';
import { ToastrService } from 'ngx-toastr';
import { DataHoraService } from '../../../shared/services/datahora.service';

@Component({
  selector: 'app-anexar-arquivo',
  templateUrl: './anexar-arquivo.component.html',
  styleUrls: ['./anexar-arquivo.component.scss'],
  providers: [],
})
export class AnexarArquivoComponent implements OnInit {
  @Input() registroAtual!: Atendimento;
  @Output() registroAlterado = new EventEmitter();
  progressVisible = "false";

  constructor(
    // private readonly atendimentoService: AtendimentoService,
    // private readonly spinner: SpinnerOverlayService,
    private readonly dataHoraService: DataHoraService,
    private readonly googleDriveService: GoogleDriveService,
    private readonly router: Router,
    private readonly toastr: ToastrService
  ) {}

  atualizarListaDocumentos(documento: DocumentoAtendimento) {
    this.registroAtual.atendimento.documentoAtendimentoCollection =
      this.registroAtual.atendimento.documentoAtendimentoCollection?.filter(
        (item) => item !== documento
      );
  }

  public uploader: FileUploader = new FileUploader({
    allowedMimeType: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/vnd.ms-word.document.macroEnabled.12',
      'application/vnd.ms-word.template.macroEnabled.12',
      'application/vnd.oasis.opendocument.text', // *.odt
      'application/pdf', // *.pdf
      'video/webm', // *.webm
    ],
    maxFileSize: 300 * 1024 * 1024, // 300M
  });

  public removeDocumento(documento: DocumentoAtendimento) {
    console.log('Google drive service - Remover arquivo');
    const x = documento.idDocumentoDrive + '';

    // Remover do google drive
    this.googleDriveService.removerArquivoDrive(x);

    // Remover do objeto
    this.atualizarListaDocumentos(documento);

    // Emitir o evento para atualizar o objeto passado no INPUT
    this.registroAlterado.emit({ registroAlterado: this.registroAtual });
  }

  ngOnInit(): void {
    this.uploader.onWhenAddingFileFailed = (
      item: FileLikeObject,
      filter: { name: string; fn: any },
      options: any
    ) => {
      this.toastr.error(
        filter.name === 'fileSize'
          ? 'Tamanho do arquivo excede o máximo permitido'
          : 'Tipo Arquivo Inválido'
      );
      console.log(
        filter.name === 'fileSize'
          ? 'Tamanho do arquivo excede o máximo permitido'
          : 'Tipo Arquivo Inválido'
      );
    };

    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      console.log('Adicionando arquivo');
      let erroValidacao = false;
      const caracteresValidos =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789ÇÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÃẼĨÕŨÄËÏÖÜÑ -_';

      const nomeArquivo = fileItem._file.name.substring(
        0,
        fileItem._file.name.lastIndexOf('.')
      );
      for (let index = 0; index < nomeArquivo.length; index++) {
        const caracter = nomeArquivo.charAt(index).toUpperCase();

        if (caracteresValidos.indexOf(caracter) < 0) {
          erroValidacao = true;
          break;
        }
      }

      if (erroValidacao) {
        this.toastr.error(
          'O nome do arquivo deve conter apenas letras, números, espaço, hífen (-) ou sublinhado (_)'
        );
        // console.log('O nome do arquivo deve conter apenas letras, números, espaço, hífen (-) ou sublinhado (_)');
      } else if (
        fileItem._file.type !== 'video/webm' &&
        fileItem._file.size > 10 * 1024 * 1024
      ) {
        this.toastr.error('Tamanho do arquivo excede o máximo permitido'); //10MB
        // console.log('Tamanho do arquivo excede o máximo permitido');
      } else if (fileItem._file.name.length > 60) {
        this.toastr.error(
          'Nome do arquivo excede o tamanho máximo permitido (60 caracteres)'
        );
        // console.log('Nome do arquivo excede o tamanho máximo permitido (60 caracteres)');
      } else if (this.registroAtual.atendimento.documentoAtendimentoCollection?.length == 10)
        this.toastr.error(
          'Quantidade máxima de arquivos é 10'
        );
      else {

        this.progressVisible = "true";
        //this.spinner.show();
        const documento: DocumentoAtendimento = {
          // idDocumento: '',
          nomeDocumento: fileItem._file.name.toString(),
          dataHoraInclusaoDocumento: this.dataHoraService.obtemDataHora(),
          nivelSigilo: 0,
          // idPessoa: null,
          idDocumentoDrive: '',
          // idAtendimento: this.registroAtual.atendimento.idAtendimento,
          // idAtendimento: '',
          // taxonomiaArquivo: null,
        };

        const file = new FormData();
        file.set('file', fileItem._file);
        file.set('nome', fileItem._file.name);
        file.set('dir', '');

        console.log('Google drive service - Gravar arquivo');
        this.googleDriveService.upload(file).subscribe({
          next: (resp) => {
            documento.idDocumentoDrive = resp;
            // console.log("idDocumentoDrive: " + documento.idDocumentoDrive);
            // console.log("nomeDocumentoDrive: " + documento.nomeDocumento);
            // console.log("dataHora: " + documento.dataHoraInclusaoDocumento);

            // Inserir o
            this.registroAtual.atendimento.documentoAtendimentoCollection?.push(
              documento
            );
            // console.log("size " + this.registroAtual.atendimento.documentoAtendimentoCollection?.length)

            // Emitir o evento para atualizar o objeto passado no INPUT
            this.registroAlterado.emit({
              registroAlterado: this.registroAtual,
            });
            this.progressVisible = "false";
            //    this.spinner.hide();
          },
          //   error: (erro) => {
          //    this.spinner.hide();
          //    this.toastr.error(erro.message);
          //    },
        });
      }
    };
  }
}
