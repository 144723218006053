<script>
  function goBack() {
      window.history.back()
  }
</script>

<form #formulario="ngForm" autocomplete=off (ngSubmit)="onSubmit(formulario)">
  <!-- <mat-card class="home logo mat-elevation-z3">
    <mat-card-content>

      <mat-card-title class="title">
        <img mat-card-x1-image src="../assets/img/logo-mppr.png" alt="logo do MPPR" >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <b>Perguntas Mais Frequentes</b>
      </mat-card-title>
    </mat-card-content>
  </mat-card> -->

  <!-- Componente para título da aplicação -->
  <app-titulo-aplicacao
    texto="MP Atende - Perguntas Mais Frequentes">
  </app-titulo-aplicacao>

  <mat-accordion #perguntaResposta name="perguntaResposta" id="perguntaResposta" >
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false"
                        *ngFor="let perguntaResposta of perguntaRespostas | async" >

      <mat-expansion-panel-header *ngIf="perguntaResposta.ativo != 'N'">
        <mat-panel-title >
          {{perguntaResposta.descPergunta}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <p  *ngIf="perguntaResposta.ativo != 'N'"><b>Resposta:</b> &nbsp;&nbsp;{{perguntaResposta.descResposta}}</p>
      <br>

      <mat-expansion-panel-footer *ngIf="perguntaResposta.ativo != 'E'">

        <b>
          <label for="radioidentificado">Essa informação foi útil?</label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <mat-radio-group class="radio-group" (change)="radioButtonGroupIdentificado($event)"  color=primary size="50%"
            name="radioidentificado" id="radioidentificado" [(ngModel)]=perguntaResposta.ativo>
            <mat-radio-button id="S{{perguntaResposta.idPerguntaResposta}}" class="radio-button" value="E">Sim</mat-radio-button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <mat-radio-button id="N{{perguntaResposta.idPerguntaResposta}}" class="radio-button" value="E" >Não</mat-radio-button>
          </mat-radio-group>
        </b>

      </mat-expansion-panel-footer>

    </mat-expansion-panel>

  </mat-accordion>

        <!--  <button routerLink="/atendimento" class="edit">
        <i class="material-icons">
            edit
        </i>
    </button>-->
  <br>
  <!-- <button mat-raised-button color="primary" onclick="{ window.history.go(-1); }">Voltar</button> -->
  <!-- <button mat-raised-button color="primary" (click)="goPlaces()">Voltar</button>  -->
</form>
