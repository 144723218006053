import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpf',
})
export class CpfPipe implements PipeTransform {
  //Pipe do epromp
  transform(value: string): string {
    if (value != null) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      return '';
    }
  }

  // transform(value: string): string {
  //   let valorFormatado = value + '';

  //   valorFormatado = valorFormatado
  //     .padStart(11, '0') // Tamanho menor que 11, completa com zeros à esquerda
  //     .substring(0, 11) // Tamanho maior que 11, pega até a décima primeira posição
  //     .replace(/[^0-9]/, '') // Retirar qualquer valor que não seja número
  //     .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'); // Extrai 3 grupos de 3 dígito e depois 1 de 2 dígitos para formatação

  //   return valorFormatado;
  // }
}
