import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { SelecionarPaisComponent } from './selecionar-pais/selecionar-pais.component';
// import { SelecionarSexoComponent } from './selecionar-sexo/selecionar-sexo.component';

@NgModule({
  declarations: [
    // SelecionarSexoComponent,
    // SelecionarPaisComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ComboboxModule { }
